import { Button, CircularProgress, Modal, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import SharedContent from "./SharedContent";
import LegContent from "./LegContent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/TradeIdeaModal/EditIcon.svg";
import { ReactComponent as InfoToolTip } from "../../assets/icons/InfoTooltip.svg";
import {
  cancelOrder,
  executeOrder,
  mapTradeForPreview,
  previewOrder,
} from "../../redux/slices/tradeExecutionSlice";
import { ErrorMessage, SuccessMessage, WarningMessage } from "./InfoMessage";
import OverlayMessage from "./OverlayMessage";
import { OrderTypeSwitcher } from "../../assets/icons/TradeIdeaModal/OrderTypeSwitcher";
import { ReactComponent as LeftArrow } from "../../assets/icons/TradeIdeaModal/LeftArrow.svg";
import {
  constructOptionSymbols,
  capitalizeFirstLetter,
  formatDate,
  isTradeIdeaExpired,
  numberFormatter,
  getClosingSide,
  isWithinMarketHours,
} from "./helpers";
import { updateSelectedTradeAfterExecution, updateTradeIdea } from "../../redux/slices/tradeIdeasSlice";
import { Order, RootState, TradeIdea, TradeStrategies, ErrorType } from "../../assets/interfaces/interfaces";
import { calculateProfitLossForSingle } from "../PerformanceDashboard/OpenPositions";
import { ReactComponent as FilledSquareMinus } from "../../assets/icons/TradeIdeaModal/FilledSquareMinus.svg";
import { ReactComponent as FilledSquarePlus } from "../../assets/icons/TradeIdeaModal/FilledSquarePlus.svg";
import { ReactComponent as RegenerateIcon } from "../../assets/icons/TradeIdeaModal/RegenerateIcon.svg";
import { fetchMarketDataForOption } from "../api/fetchMarketDataForOption";
import CancelSLTPModal from "./CancelSLTPModal";

type ModalParentProps = {
  open: boolean;
  onClose: () => void;
  positionType?: "open" | "close";
  selectedTrade?: TradeIdea;
  orderToClose?: Order;
  handleSendMessage?: (text: string, input_type: string) => void;
};

interface OverlayState {
  showExecuted: boolean;
  showError: boolean;
  errorType: ErrorType | null;
}
export default function ModalParent({
  open,
  onClose,
  positionType = "open",
  selectedTrade,
  orderToClose = null,
  handleSendMessage,
}: ModalParentProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedOption: stateSelectedOption } = useSelector(
    (state: { tradeIdeas }) => state.tradeIdeas,
  );
  const selectedOption = selectedTrade || stateSelectedOption;
  const virtualAccount = useSelector(
    (state: { tradierAccount }) => state.tradierAccount.account,
  );
  const {
    mappedTrade,
    isPreviewLoading,
    isExecuteLoading,
    isCancelLoading,
    error: placeOrderError,
  } = useSelector((state: { tradeExecution }) => state.tradeExecution);

  const [tradeState, setTradeState] = useState({
    isPreviewed: false,
    isExecuted: false,
    isExpired: false,
  });
  const updateTradeState = (updates) => {
    setTradeState(prevState => ({
      ...prevState,
      ...updates,
    }));
  };
  const { isPreviewed, isExecuted, isExpired } = tradeState;
  const isClose = positionType === "close";
  const [quantity, setQuantity] = useState(1);
  const [isMarket, setIsMarket] = useState(true);
  const [platformFees, setPlatformFees] = useState<number>(0);
  const [previewedOrder, setPreviewedOrder] = useState(null);
  const [updatedTradeIdea, setUpdatedTradeIdea] = useState(null);

  const [tradeSettings, setTradeSettings] = useState({
    stopLoss: null,
    isStopLossActive: true,
    takeProfit: null,
    isTakeProfitActive: true,
    limit: null,
  });
  const handleTradeSettingsChange = (field, newValue) => {
    setTradeSettings(prevSettings => ({
      ...prevSettings,
      [field]: newValue,
    }));
  };

  const { pastMessages } = useSelector((state: RootState) => state.session);
  let legs = orderToClose?.legs ?? selectedOption?.trade_idea.legs;
  const isMultiLeg = legs.length > 1;

  const [overlayState, setOverlayState] = useState<OverlayState>({
    showExecuted: false,
    showError: false,
    errorType: null,
  });
  const updateOverlayState = (newState) => {
    setOverlayState(prev => ({ ...prev, ...newState }));
  };
  const [showCancelSLTPWarningPref, setShowCancelSLTPWarningPref] = useState(false);

  useEffect(() => {
    if (!selectedOption) return;
    const { trade_idea, created_at, preview_result } = selectedOption;
    const { stop, profit, legs } = trade_idea;

    setPreviewedOrder(null);
    const hasPreviewData = preview_result && typeof preview_result === "object" && Object.keys(preview_result).length > 0;

    setTradeState(prevState => ({
      ...prevState,
      isPreviewed: isClose ? trade_idea.position === "close" : hasPreviewData,
      isExpired: isTradeIdeaExpired(created_at) && !isClose,
      isExecuted: isClose ? trade_idea.position === "close" : hasPreviewData,
    }));

    setQuantity(1);
    if (isClose) {
      setQuantity(parseInt(orderToClose?.total_quantity) || 1);
      setIsMarket(true);
    }
    if (hasPreviewData) {
      setQuantity(legs[0]?.quantity);
      setPreviewedOrder(preview_result);
    }
    setTradeSettings(prevSettings => ({
      ...prevSettings,
      stopLoss: stop || null,
      takeProfit: profit || null,
      limit: legs[0]?.premium || null,
      isStopLossActive: true,
      isTakeProfitActive: true,
    }));

    if (isClose) {
      const doNotShowSLTPWarning = localStorage.getItem("hide-sltp-warning");
      if (doNotShowSLTPWarning) {
        setShowCancelSLTPWarningPref(JSON.parse(doNotShowSLTPWarning));
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isExecuted && previewedOrder) {
      const previewInfo = previewedOrder?.orders[0];
      setPlatformFees(quantity * previewInfo.commission);
    }
    else {
      const isMultiLegTrade = legs.length > 1;
      setPlatformFees(quantity * 0.35 * (isMultiLegTrade ? 2 : 1));
    }
  }, [previewedOrder, quantity, isExecuted]);

  useEffect(() => {
    if (!previewedOrder || !Array.isArray(previewedOrder.orders)) return;

    const previewInfo = previewedOrder.orders[0];
    if (!previewInfo?.request_date) return;

    const checkExpiration = () => {
      const isPreviewExpired = isTradeIdeaExpired(previewInfo.request_date);
      if (isPreviewExpired && !isClose) {
        updateTradeState({ isExpired: true });
      }
    };

    checkExpiration();

    const intervalId = setInterval(checkExpiration, 60000);

    return () => clearInterval(intervalId);
  }, [previewedOrder, positionType]);

  // polling for symbol and option quotes
  const symbolArr = [
    selectedOption?.trade_idea.symbol,
    constructOptionSymbols(selectedOption?.trade_idea),
  ];
  const marketData = fetchMarketDataForOption(virtualAccount.external_id, symbolArr);

  const { symbolQuote, optionQuotes } = useMemo(() => {
    if (!marketData || !marketData.quotes) {
      return { symbolQuote: null, optionQuotes: [] };
    }
    const quotesArray = marketData?.quotes?.quote;
    if (Array.isArray(quotesArray)) {
      const [symbolQuote = null, ...optionQuotes] = quotesArray;
      return { symbolQuote, optionQuotes };
    }
    return { symbolQuote: null, optionQuotes: [] };
  }, [marketData]);

  const getMarketValue = (side, optionSymbol) => {
    const quote = optionQuotes?.find(quoteInfo => quoteInfo?.symbol === optionSymbol);
    if (side === "sell_to_close" || side === "buy_to_open") {
      return quote?.bid;
    }
    else {
      return quote?.ask;
    }
  };

  const calculateTotals = useMemo(() => {
    let totalSellPremium = 0;
    let totalBuyPremium = 0;
    let totalProceeds = 0;
    let totalCost = 0;
    let netValue = 0;

    if (selectedOption) {
      if (!orderToClose?.legs && isClose) {
        legs = [orderToClose];
      }
      legs.forEach((leg, index) => {
        const quote = optionQuotes?.find(quoteInfo => quoteInfo?.symbol === leg?.option_symbol) || optionQuotes?.[index];
        if (!quote) return;

        const midPrice = (quote.bid + quote.ask) / 2; // 0.
        const validMidPrice = Number.isNaN(midPrice) ? 0 : midPrice; // 29.1

        const isSell = leg?.buy_sell === "sell";
        const isBuy = leg?.buy_sell === "buy"; // buy

        if (isClose) {
          const currentValueOfLeg = getMarketValue(leg.side, leg.option_symbol); // 0.5
          if (leg.side === "sell_to_close" || leg.side === "buy_to_open") {
            netValue += currentValueOfLeg;
            totalCost += (currentValueOfLeg * 100 * quantity) || 0;
          }
          else {
            netValue -= currentValueOfLeg;
            totalProceeds += (currentValueOfLeg * 100 * quantity) || 0;
          }
        }
        else {
          if (isSell) {
            totalSellPremium += leg.premium || validMidPrice;
            totalProceeds += (quote.bid * 100 * quantity) || 0;
          }
          else if (isBuy) {
            totalBuyPremium += leg.premium || validMidPrice;
            totalCost += (quote.ask * 100 * quantity) || 0;
          }
        }
      });
    }

    return {
      totalSellPremium,
      totalBuyPremium,
      totalProceeds,
      totalCost,
      netValue,
    };
  }, [legs, optionQuotes, quantity, selectedOption]);

  const netAmount = calculateTotals.totalSellPremium - calculateTotals.totalBuyPremium;

  const labelForNetCreditDebit = netAmount >= 0 ? "Net Credit" : "Net Debit";
  const labelForNetCreditDebitClose = calculateTotals.netValue >= 0 ? "Net Credit" : "Net Debit";
  const formattedNetAmount = numberFormatter(Math.abs(netAmount));
  const formattedProceeds = (Math.abs(calculateTotals.totalProceeds - calculateTotals.totalCost));

  const [isRotatedStopLoss, setIsRotatedStopLoss] = useState(false);
  const [isRotatedTakeProfit, setIsRotatedTakeProfit] = useState(false);

  const handleSLTPToggleSwitch = (field, isRotated, setIsRotated) => {
    const handleClick = (isActive) => {
      setIsRotated(true);
      setTimeout(() => {
        setTradeSettings(prevSettings => ({
          ...prevSettings,
          [field]: isActive,
        }));
        setIsRotated(false);
      }, 200);
    };

    return tradeSettings[field]
      ? (
          <FilledSquareMinus
            className={` cursor-pointer duration-500 ease-in-out me-2 transform transition-transform ${
              isRotated ? "rotate-90" : ""
            }`}
            onClick={() => handleClick(false)}
          />
        )
      : (
          <FilledSquarePlus
            className={`cursor-pointer duration-500 ease-in-out me-2 transform transition-transform ${
              isRotated ? "-rotate-90" : ""
            }`}
            onClick={() => handleClick(true)}
          />
        );
  };

  const CustomInput = ({ value, onChange, field, isActive }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const handleEditClick = () => {
      setIsEditing(true);
    };

    const handleInputBlur = () => {
      setIsEditing(false);
      onChange(field, inputValue);
    };

    if (isEditing) {
      return (
        <div className="flex items-center justify-between w-full">
          <input
            className="w-1/3 ml-auto px-2 py-1"
            type="number"
            step="0.01"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoFocus
          />
        </div>
      );
    }
    return (
      <div className={`flex items-center justify-between w-full ${!isActive ? "opacity-30" : ""}`}>
        {!isPreviewed && (
          <button onClick={handleEditClick} disabled={!isActive}>
            <EditIcon className="fill-black me-1 cursor-pointer" />
          </button>
        )}
        <span className="tabular-nums">
          {numberFormatter(inputValue)}
        </span>
      </div>
    );
  };

  const CustomizedToolTip = styled(({ className, placement = "top", ...props }: TooltipProps) => {
    return (
      <Tooltip {...props} classes={{ popper: className }} placement={placement} />
    );
  })(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      paddingRight: "8px !important",
      paddingLeft: "8px !important",
      textAlign: "center",
      maxWidth: 252,
      fontSize: "12px",
      margin: "0px",
      boxSizing: "border-box",
    },
  }));

  const optionContentForOpenPos = [
    {
      key: "orderType",
      label: "Order Type",
      value: (() => {
        if (isPreviewed) {
          return isMarket ? "Market" : "Limit";
        }

        if (isMultiLeg) {
          return "Market";
        }

        return (
          <OrderTypeSwitcher isMarket={isMarket} setIsMarket={setIsMarket} />
        );
      })(),
    },
    { key: "timeInForce", label: "Time-in-Force", value: "Good Till Day" },
    {
      key: "strategy",
      label: "Strategy",
      value: selectedOption?.trade_idea.option_strategy,
    },
    {
      key: "platformFees",
      label: (
        <div className="flex items-center gap-x-2">
          Platform Fees
          <CustomizedToolTip
            title="Platform fees are determined by our partner broker platform."
          >
            <span>
              <InfoToolTip className="cursor-pointer" />
            </span>
          </CustomizedToolTip>
        </div>
      ),
      value: numberFormatter(platformFees),
    },
    {
      key: "orderCostForSingle",
      label: (
        <p>
          {selectedOption?.trade_idea.legs[0]?.buy_sell === TradeStrategies.Buy
            ? "Order Cost"
            : "Order Proceeds"}
        </p>
      ),
      value: numberFormatter(formattedProceeds),
    },
    {
      key: "orderCostForMulti",
      label: (
        <p>
          {labelForNetCreditDebit === "Net Credit"
            ? "Order Proceeds"
            : "Order Cost"}
        </p>
      ),
      value: numberFormatter(formattedProceeds),
    },
    {
      key: "estimatedTotalProceeds",
      label: (
        <div className="flex items-center gap-x-2">
          Total Cost (Est.)
          <CustomizedToolTip
            title="Estimated total cost may contain additional margin requirements as determined by the brokerage platform."
          >
            <span>
              <InfoToolTip className="cursor-pointer" />
            </span>
          </CustomizedToolTip>
        </div>
      ),
      value: previewedOrder?.cost
        ? numberFormatter(previewedOrder.cost)
        : "Pending",
    },
    {
      key: "breakevenPrice",
      label: "Breakeven Price",
      value: Array.isArray(selectedOption?.trade_idea?.break_even_price) && selectedOption.trade_idea.break_even_price.length > 0
        ? selectedOption.trade_idea.break_even_price
          .map(price => numberFormatter(price))
          .join(" / ")
        : "N/A",
    },
    {
      key: "proceedsAfterFees",
      label: "Proceeds after Fees",
      value: formattedProceeds,
    },
    {
      key: "probabilityOfProfit",
      label: "Probability of Profit",
      value: selectedOption?.trade_idea.prob_of_profit
        ? `${selectedOption.trade_idea.prob_of_profit}%`
        : "N/A",
    },
    {
      key: "maxLoss",
      label: "Max Loss",
      value: (() => {
        const maxLoss = selectedOption?.trade_idea.max_loss;

        if (maxLoss == null) {
          return "N/A";
        }

        const formattedValue = numberFormatter(maxLoss);
        return formattedValue;
      })(),
    },

    { key: "costAfterFees", label: "Cost after Fees", value: 0 },
    { key: "emptyRow", label: "", value: null },
    {
      key: "bidask", // bidask for single leg
      label: (
        <div className="flex flex-1 justify-between">
          <div className="flex gap-x-1">
            <p className="text-mui-green-text-success">Bid</p>
            <span>/</span>
            <p className="text-mui-red-success">Ask</p>
          </div>
        </div>
      ),
      value: (
        <div>
          {optionQuotes?.length > 0
            ? (
                <div className="flex items-center gap-x-1">
                  <span className="text-mui-green-text-success">
                    {numberFormatter(optionQuotes[0].bid)}
                  </span>
                  <span>/</span>
                  <span className="text-mui-red-success">
                    {numberFormatter(optionQuotes[0].ask)}
                  </span>
                </div>
              )
            : (
                <p>No quotes available</p>
              )}
        </div>
      ),
    },
    {
      key: "nettCreditOrDebit",
      label: selectedOption.trade_idea?.option_strategy === TradeStrategies.Straddle ? "Premium (Strategy)" : labelForNetCreditDebit,
      value: formattedNetAmount || 0,
    },
    {
      key: "limit",
      label: "Limit",
      value: (
        <CustomInput
          value={tradeSettings.limit}
          onChange={handleTradeSettingsChange}
          field="limit"
          isActive={true}
        />
      ),
    },
    {
      key: "maxProfit",
      label: "Max Profit",
      value: (() => {
        const maxProfit = selectedOption?.trade_idea.max_profit;

        if (maxProfit == null) {
          return "N/A";
        }

        const formattedValue = numberFormatter(maxProfit);
        return formattedValue;
      })(),
    },
    {
      key: "stopLoss",
      label: (
        <div className="flex items-center gap-x-2 tabular-nums">
          <div className={`flex items-center gap-x-2 tabular-nums ${!tradeSettings.isStopLossActive ? "opacity-30" : ""}`}>
            Stop Loss
            <CustomizedToolTip
              title="Set automatically to 10% of order value."
            >
              <span>
                <InfoToolTip className="cursor-pointer" />
              </span>
            </CustomizedToolTip>
          </div>
          {!isMarket && !isPreviewed && handleSLTPToggleSwitch("isStopLossActive", isRotatedStopLoss, setIsRotatedStopLoss)}
        </div>
      ),
      value:
      isMarket
        ? "N/A"
        : (
            <CustomInput
              value={tradeSettings.stopLoss}
              onChange={handleTradeSettingsChange}
              field="stopLoss"
              isActive={tradeSettings.isStopLossActive}
            />
          ),
    },
    {
      key: "takeProfit",
      label: (
        <div className="flex items-center gap-x-2 tabular-nums">
          <div className={`flex items-center gap-x-2 tabular-nums ${!tradeSettings.isTakeProfitActive ? "opacity-30" : ""}`}>
            Take Profit
            <CustomizedToolTip
              title="Set automatically to 10% of order value."
            >
              <span>
                <InfoToolTip className="cursor-pointer" />
              </span>
            </CustomizedToolTip>
          </div>
          {!isMarket && !isPreviewed && handleSLTPToggleSwitch("isTakeProfitActive", isRotatedTakeProfit, setIsRotatedTakeProfit)}
        </div>
      ),
      value: isMarket
        ? (
            "N/A"
          )
        : (
            <CustomInput
              value={tradeSettings.takeProfit}
              onChange={handleTradeSettingsChange}
              field="takeProfit"
              isActive={tradeSettings.isTakeProfitActive}
            />
          ),
    },
    {
      key: "premium",
      label: "Premium",
      value: numberFormatter(selectedOption.trade_idea.legs[0]?.premium || 0),
    },
    {
      key: "action",
      label: "Action",
      value: capitalizeFirstLetter(selectedOption.trade_idea.legs[0]?.buy_sell || 0),
    },
    {
      key: "contractType",
      label: "Contract Type",
      value: capitalizeFirstLetter(selectedOption.trade_idea.legs[0]?.put_call || 0),
    },
  ];

  const optionContentForClosePos = orderToClose && [
    { key: "orderType", label: "Order Type", value: "Market" },
    { key: "action", label: "Action", value: !isMultiLeg && getClosingSide(orderToClose?.side) },
    { key: "timeInForce", label: "Time-in-Force", value: "Good Till Day" },
    { key: "contractType", label: "Contract Type", value: capitalizeFirstLetter(selectedOption?.trade_idea.legs[0].put_call) },
    {
      key: "platformFees",
      label: (
        <div className="flex items-center gap-x-2">
          Platform Fees
          <CustomizedToolTip title="Platform fees are determined by our partner broker platform">
            <span>
              <InfoToolTip className="cursor-pointer" />
            </span>
          </CustomizedToolTip>
        </div>
      ),
      value: numberFormatter(platformFees),
    },
    {
      key: "profitLoss",
      label: "P&L",
      value: isNaN(optionQuotes[0]?.bid) || isNaN(optionQuotes[0]?.ask)
        ? ""
        : numberFormatter(
          calculateProfitLossForSingle(orderToClose, getMarketValue(orderToClose.side, orderToClose.option_symbol)),
        ),
    },
    { key: "proceedsOfClosing",
      label: selectedOption.trade_idea.legs[0]?.buy_sell === TradeStrategies.Sell ? "Cost of Closing Position" : "Proceeds of Closing Position",
      value: orderToClose?.type === "market" ? numberFormatter(formattedProceeds) : numberFormatter(tradeSettings.limit * 100) },
    {
      key: "orderCostForSingle",
      label: (
        <p>
          {orderToClose?.side === "sell_to_open"
            ? "Order Cost"
            : "Order Proceeds"}
        </p>
      ),
      value: numberFormatter(formattedProceeds),
    },
    {
      key: "orderCostForMulti",
      label: (
        <p>
          {labelForNetCreditDebitClose === "Net Credit"
            ? "Order Proceeds"
            : "Order Cost"}
        </p>
      ),
      value: numberFormatter(formattedProceeds),
    },
    {
      key: "estimatedTotalProceeds",
      label: (
        <p>
          Total Proceeds (Est.)
        </p>
      ),
      value: (() => {
        // Safely check if preview_result and orders exist and are not empty
        const orders = selectedOption?.preview_result?.orders;
        const marginChange
          = orders && orders.length > 0 && orders[0]?.margin_change !== null
            ? orders[0].margin_change
            : 0;

        return marginChange > 0
          ? `${numberFormatter(-marginChange + formattedProceeds + platformFees)}` // will be buy when closing -> order costs
          : `${numberFormatter(marginChange + formattedProceeds - platformFees)}`; // will be sell when closing -> order proceeds
      })(),
    },
    { key: "emptyRow", label: "", value: null },
    {
      key: "nettCreditOrDebit",
      label: labelForNetCreditDebit,
      value: numberFormatter(calculateTotals.netValue) || 0,
    },
  ];

  const optionContent = !isClose ? optionContentForOpenPos : optionContentForClosePos;

  const handleMapping = async () => {
    const defaultDuration = "day";
    const position = positionType === "open" ? "open" : "close";

    try {
      if (!quantity || quantity === undefined) {
        throw new Error("Quantity is not defined");
      }

      const updatedOption = {
        ...selectedOption.trade_idea,
        stop: isMarket || !tradeSettings.isStopLossActive ? null : tradeSettings.stopLoss,
        profit: isMarket || !tradeSettings.isTakeProfitActive ? null : tradeSettings.takeProfit,
        position,
        type: isMarket || isClose ? "market" : "limit",
        duration: defaultDuration,
        legs: selectedOption.trade_idea.legs.map((leg) => {
          let buy_sell = leg.buy_sell;

          if (isClose) {
            buy_sell = leg.buy_sell === "buy" ? "sell" : "buy";
          }

          return {
            ...leg,
            type: isMarket ? "market" : "limit",
            quantity,
            duration: defaultDuration,
            buy_sell,
            premium: isMarket ? leg.premium : tradeSettings.limit,
          };
        }),
      };

      setUpdatedTradeIdea(updatedOption);
      const { payload } = await dispatch(
        mapTradeForPreview({
          provider: "tradier",
          selectedOption: updatedOption,
        }),
      );

      return payload;
    }
    catch (error) {
      console.error("Error during mapTradeForPreview:", error);
    }
  };

  const [showCancelSLTPWarning, setShowCancelSLTPWarning] = useState(false);
  const handleCancelOrder = async () => {
    try {
      await dispatch(
        cancelOrder({
          accountId: virtualAccount.external_id,
          orderId: orderToClose.parent_id,
          vendor: "tradier",
        }),
      ).unwrap();
      // continue to preview even if cancel order returns error
      console.log("Order cancelled successfully (or not), starting to preview...");
    }
    catch (error) {
      console.error("Error during cancellation of secondary orders: ", error);
    }
  };

  const executePreviewWithRetries = async (orderPayload, maxRetries = 3) => {
    let attempt = 0;

    while (attempt < maxRetries) {
      try {
        const previewOrderResult = await dispatch(
          previewOrder({
            accountId: virtualAccount.external_id,
            order: orderPayload,
          }),
        ).unwrap();

        setPreviewedOrder(previewOrderResult);
        return;
      }
      catch (error) {
        attempt++;
        console.error(`Error during preview attempt ${attempt} of ${maxRetries}:`, error);

        if (attempt >= maxRetries) {
          throw new Error("Error during previewOrder dispatch after retries");
        }
      }
    }
  };
  const handlePreview = async () => {
    if (!isWithinMarketHours()) {
      updateOverlayState({ showError: true, errorType: "afterhours" });
      return;
    }
    // for OTOCO/OCO orders need to cancel secondaries first (use parent id of that order) - filter out limit orders with no secondary orders
    if (isClose && orderToClose.type === "limit" && !orderToClose.raw_order) {
      await handleCancelOrder();
    }

    try {
      const payload = await handleMapping();
      const transformedPayload = {
        orders: [payload],
      };

      if (isClose && orderToClose.type === "limit" && !orderToClose.raw_order) {
        await executePreviewWithRetries(transformedPayload);
      }
      else {
        const previewOrderResult = await dispatch(
          previewOrder({
            accountId: virtualAccount.external_id,
            order: transformedPayload,
          }),
        ).unwrap();
        setPreviewedOrder(previewOrderResult);
      }
    }
    catch (error) {
      console.error("Error during previewOrder dispatch: ", error);
      updateOverlayState({ showError: true, errorType: "preview" });
    }
    finally {
      updateTradeState({ isPreviewed: true, isExpired: false });
    }
  };

  const handleExecute = async () => {
    if (!isWithinMarketHours()) {
      updateOverlayState({ showError: true, errorType: "afterhours" });
      return;
    }
    try {
      const [updatedTradeResult] = await Promise.all([
        dispatch(
          updateTradeIdea({
            previewedOrder,
            updatedTradeIdea,
            tradeIdeaId: selectedOption.id,
          }),
        ).unwrap(),
        dispatch(
          executeOrder({
            order: mappedTrade,
            accountId: virtualAccount.external_id,
            tradeIdeaId: selectedOption.id,
            vendor: "tradier",
          }),
        ).unwrap(),
      ]);
      // update changes made to trade idea (SLTP, quantity, limit) + save preview costs
      await dispatch(updateSelectedTradeAfterExecution(updatedTradeResult));
    }

    catch (error) {
      console.error("Error executing order: ", error);
      updateOverlayState({ showError: true, errorType: "execute" });
    }
    finally {
      updateTradeState({ isExecuted: true });
      updateOverlayState({ showExecuted: true });
    }
  };

  const handleRegenerateMessage = async () => {
    const foundMessage = pastMessages.find(
      message => message.message_id === selectedOption.message_id,
    );
    if (foundMessage) {
      handleSendMessage(foundMessage.user_query, "user_type");
    }
    onClose();
  };
  // logic for showing success, error messages, and appropriate buttons

  const shouldShowPreviewSuccess
    = isPreviewed
    && !isExecuted
    && !isExpired
    && !isPreviewLoading
    && !placeOrderError;
  const shouldShowError
    = (isPreviewed || isExecuted) && placeOrderError && overlayState.errorType;

  return (
    <>
      <Modal open={open} onClose={() => {}}>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow w-modal-lg text-mui-black-87 bg-white rounded min-h-[522px]">
          {overlayState.showExecuted && (
            <OverlayMessage closeExecutedOverlay={() => setOverlayState(prev => ({ ...prev, showExecuted: false }))} isClosing={isClose} />
          )}
          {overlayState.showError && (
            <OverlayMessage
              closeErrorOverlay={() => setOverlayState(prev => ({ ...prev, showError: false }))}
              errorMessage={placeOrderError?.error || placeOrderError}
              errorType={overlayState.errorType}
            />
          )}
          {showCancelSLTPWarning && <CancelSLTPModal open={showCancelSLTPWarning} closeParentModal={onClose} closeWarning={() => setShowCancelSLTPWarning(false)} handlePreview={handlePreview} />}
          <section className="flex flex-col gap-y-4 bg-mui-blue-gray p-6 rounded-lg rounded-b-none pb-0">
            <SharedContent
              onClose={onClose}
              optionContent={optionContent}
              quantity={quantity}
              setQuantity={setQuantity}
              isPreviewed={isPreviewed}
              isExecuted={isExecuted}
              symbolQuote={symbolQuote}
              orderToClose={orderToClose}
              selectedTrade={selectedOption}
            />
          </section>
          <section className="bg-white p-6 rounded-b-lg flex flex-col gap-y-6 ">
            <LegContent
              optionContent={optionContent}
              optionQuotes={optionQuotes}
              isMarket={isMarket}
              quantity={quantity}
              selectedTrade={selectedOption}
              isClosePosition={positionType === "close"}
            />
            <div className="flex flex-col items-center gap-y-4">
              {!isPreviewed && !isExecuted && !isExpired && (
                <div className="w-full sm:w-80 self-center">
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    className="tradesButton-submit"
                    disabled={isPreviewLoading || isCancelLoading}
                    onClick={() => {
                      if (isClose && !showCancelSLTPWarningPref && orderToClose.type === "limit" && !orderToClose.raw_order) {
                        setShowCancelSLTPWarning(true);
                      }
                      else {
                        handlePreview();
                      }
                    }}
                  >
                    {isPreviewLoading || isCancelLoading
                      ? (
                          <div className="flex items-center">
                            <CircularProgress color="inherit" size={25} />
                          </div>
                        )
                      : (
                          "Preview"
                        )}
                  </Button>
                </div>
              )}
              {shouldShowPreviewSuccess && (
                <div className="w-full flex flex-col gap-y-6">
                  <SuccessMessage message="Ready to execute" />
                  <div className="flex gap-x-6">
                    <Button
                      variant="outlined"
                      size="large"
                      color="inherit"
                      className="tradesButton-outlined"
                      fullWidth
                      onClick={() => updateTradeState({ isPreviewed: false })}
                    >
                      <LeftArrow className="me-2" />
                      Modify
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="green-submit"
                      disabled={isExecuteLoading}
                      onClick={handleExecute}
                      fullWidth
                    >
                      {isExecuteLoading
                        ? (
                            <div className="flex items-center">
                              <CircularProgress color="inherit" size={25} />
                            </div>
                          )
                        : (
                            "Execute"
                          )}
                    </Button>
                  </div>
                </div>
              )}
              {shouldShowError && (
                <div className="w-full flex flex-col gap-y-6">
                  <ErrorMessage message="Unable to execute" />
                  <div className="w-full sm:w-80 self-center">
                    <Button
                      variant="contained"
                      size="large"
                      className="tradesButton-submit"
                      onClick={() => updateTradeState({ isExpired: false, isPreviewed: false })}
                      fullWidth
                    >
                      <LeftArrow className="pe-2" />
                      Modify
                    </Button>
                  </div>
                </div>
              )}

              {isExecuted && (
                <div className="w-full flex flex-col gap-y-6">
                  <WarningMessage
                    message={`This trade has already been executed on ${formatDate(
                      selectedOption?.preview_result.request_date || new Date(),
                    )}.`}
                  />
                </div>
              )}
              {isExpired && !isExecuted && !isPreviewed && (
                <div className="w-full flex flex-col gap-y-6">
                  <WarningMessage message="This trade idea has expired." />
                  <div className="flex gap-x-6 w-full">
                    <Button
                      variant="outlined"
                      size="large"
                      color="inherit"
                      className="tradesButton-outlined"
                      fullWidth
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      color="warning"
                      size="large"
                      className="flex gap-x-1 !text-transform-none"
                      disabled={isPreviewLoading}
                      onClick={handleRegenerateMessage}
                      fullWidth
                    >
                      <RegenerateIcon />
                      Regenerate
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="tradesButton-submit button-loading"
                      disabled={isPreviewLoading}
                      onClick={handlePreview}
                      fullWidth
                    >
                      {isPreviewLoading
                        ? (
                            <div className="flex items-center">
                              <CircularProgress color="inherit" size={25} />
                            </div>
                          )
                        : (
                            "Continue to preview"
                          )}
                    </Button>
                  </div>
                </div>
              )}
              {isExpired && isPreviewed && !isExecuted && (
                <div className="w-full flex flex-col gap-y-6">
                  <WarningMessage message="The preview of this trade idea has expired." />
                  <div className="flex gap-x-6 w-full">
                    <Button
                      variant="contained"
                      size="large"
                      className="tradesButton-submit"
                      onClick={() => updateTradeState({ isPreviewed: false })}
                      fullWidth
                    >
                      <LeftArrow className="pe-2" />
                      Modify
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="green-submit"
                      onClick={handleExecute}
                      fullWidth
                    >
                      {isExecuteLoading
                        ? (
                            <div className="flex items-center">
                              <CircularProgress color="inherit" size={25} />
                            </div>
                          )
                        : (
                            "Execute"
                          )}
                    </Button>
                  </div>
                </div>
              )}

              <CustomizedToolTip
                title="Market data changes in real time, and is only finalized at execution."
                placement="bottom"
              >
                <div className="caption cursor-pointer">
                  Why are some values different when I preview/execute?
                </div>
              </CustomizedToolTip>
            </div>
          </section>
        </div>
      </Modal>
    </>
  );
}
