const CrossSVG = (props: { color: string; opacity?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      opacity={props.opacity || 1}
      d="M5.442 4.558a.625.625 0 1 0-.884.884L9.116 10l-4.558 4.558a.625.625 0 0 0 .884.884L10 10.884l4.558 4.558a.625.625 0 0 0 .884-.884L10.884 10l4.558-4.558a.625.625 0 0 0-.884-.884L10 9.116 5.442 4.558Z"
    />
  </svg>
);
export default CrossSVG;
