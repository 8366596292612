import { Button, TableCell, TableRow } from "@mui/material";
import { isWithinMarketHours, numberFormatter } from "../OptionsTrading/helpers";
import { useEffect, useState } from "react";
import ModalParent from "../OptionsTrading/ModalParent";
import { isLimitOrder, isMultiLegOrder } from "./OrdersTable";
import { useSelector } from "react-redux";
import { StatusChip } from "./StatusChip";
import { renderContractType, renderStrategyCell } from "./OpenOrders";
import { TradeStrategies } from "../../assets/interfaces/interfaces";
import { fetchMarketDataForOption } from "../api/fetchMarketDataForOption";
import NotInMarketHoursModal from "../OptionsTrading/NotInMarketHoursModal";

const getOptionSymbolsForFilledOrders = (order) => {
  if (isMultiLegOrder(order)) {
    return order.legs.map(leg => leg.option_symbol);
  }
  if (isLimitOrder(order)) {
    return (
      order.raw_order?.option_symbol || order.raw_order?.["option_symbol[0]"]
    );
  }
  return order.option_symbol;
};

export const calculateProfitLossForSingle = (order, midPriceValue) => {
  if (midPriceValue === null) {
    return null;
  }

  const currentContractPrice
      = midPriceValue * 100 * parseInt(order.total_quantity, 10) || 0; // 130
  const initialContractPrice // 150
      = parseFloat(order.avg_fill_price)
      * 100
      * parseInt(order.exec_quantity || 0);
  const profitOrLoss = currentContractPrice - initialContractPrice; // 24 - 8
  return profitOrLoss;
};

// get the parent leg for limit orders
export const getPrimaryOrderForLimitOrders = (legs) => {
  if (!legs || legs.length === 0) {
    return null;
  }

  const primaryOrder = legs.find(leg => leg.side.endsWith("to_open"));
  return primaryOrder || null;
};

const renderQty = (isMultiLeg, order, primaryOrder) => {
  if (isMultiLeg) {
    return order.legs.map((leg, index) => (
      <TableRow key={`leg-${order.id}-${index}`}>
        <TableCell className="nested-row">
          {leg.total_quantity}
        </TableCell>
      </TableRow>
    ));
  }
  else {
    const totalQuantity = primaryOrder
      ? primaryOrder.total_quantity || 0
      : order.total_quantity;

    return totalQuantity;
  }
};

const OpenPositions = ({ filledOrders, findTradeByOrderId }) => {
  const [modalState, setModalState] = useState({
    type: null, // "closePosition" or "notInMarketHours"
    isOpen: false,
  });
  const [selectedPositionInfo, setSelectedPositionInfo] = useState({
    selectedPosition: null,
    parentId: "",
  });
  const [marketData, setMarketData] = useState(null);
  const virtualAccount = useSelector(
    (state: { tradierAccount }) => state.tradierAccount.account,
  );

  console.log("filled orders", filledOrders);

  const optionSymbolsArray = filledOrders.flatMap(order => getOptionSymbolsForFilledOrders(order));

  const data = fetchMarketDataForOption(virtualAccount.external_id, optionSymbolsArray);
  useEffect(() => {
    if (data && data.quotes) {
      const quotes = Array.isArray(data?.quotes.quote)
        ? data.quotes.quote
        : [data?.quotes.quote];

      const quotesObj = Object.fromEntries(quotes.map(quote => [quote.symbol, quote]));
      setMarketData(quotesObj);
    }
  }, [data]);

  const getMarketPrice = (optionSymbol, side) => {
    if (optionSymbol && side && marketData) {
      const data = marketData?.[optionSymbol];
      if (!data) {
        return null;
      }

      let marketValue = 0;

      if ((side === "sell_to_close" || side === "buy_to_open") && typeof data.bid === "number") {
        marketValue = data.bid;
      }
      else if ((side === "buy_to_close" || side === "sell_to_open") && typeof data.ask === "number") {
        marketValue = data.ask;
      }

      return marketValue;
    }

    return 0;
  };

  const getMidPriceForEachLeg = (leg) => {
    const midPriceForLeg = getMarketPrice(leg.option_symbol, leg.side);
    return leg.side === "sell_to_open" || leg.side === "sell_to_close"
      ? midPriceForLeg
      : -midPriceForLeg;
  };

  const getNetCreditOrDebit = (legs) => {
    return legs.reduce((total, leg) => {
      return total + getMidPriceForEachLeg(leg);
    }, 0);
  };

  const calculateProfitLossForMulti = (order) => {
    const avgFillPrice = parseFloat(order.avg_fill_price); // 0.15
    const totalQuantity = parseInt(order.total_quantity, 10);
    if (isNaN(avgFillPrice) || isNaN(totalQuantity)) {
      return 0;
    }
    const initialDebitCredit = avgFillPrice * 100 * totalQuantity; // 90
    const netCreditOrDebit = getNetCreditOrDebit(order.legs) * 100 * totalQuantity; // 180
    return initialDebitCredit - netCreditOrDebit; // -90
  };

  const calculateProfitLossForStraddle = (order) => {
    const totalCost = parseFloat(order.avg_fill_price);
    const sumOfMidPrices = order.legs
      .map(leg => getMarketPrice(leg.option_symbol, leg.side))
      .reduce((acc, midPrice) => acc + midPrice, 0);
    const profitLoss = (totalCost - sumOfMidPrices) * 100 * order.exec_quantity;
    return profitLoss;
  };

  const calculateValueForMultiLeg = (order, trade) => {
    const initialMargin = trade?.preview_result?.orders?.[0]?.margin_change ?? 0;
    const netCreditDebit = getNetCreditOrDebit(order?.legs || []);

    const totalQuantity = parseInt(order?.total_quantity, 10);
    const validTotalQuantity = Number.isNaN(totalQuantity) ? 0 : totalQuantity;

    const currentMarketValue
      = (-netCreditDebit * validTotalQuantity * 100) + initialMargin;

    return currentMarketValue;
  };

  const getCurrentContractPriceForSingle = (order) => {
    const midPriceValue = getMarketPrice(order?.option_symbol, order?.side);
    return midPriceValue * 100 * parseInt(order?.total_quantity || 0);
  };

  const renderMidPrice = (midPriceForSingle) => {
    return (
      <>

        {isNaN(midPriceForSingle) ? "" : numberFormatter(midPriceForSingle, 3)}

      </>
    );
  };

  const renderMidPriceForStraddle = (legs) => {
    const midPricesForLegs = legs.map(leg => getMarketPrice(leg.option_symbol, leg.side));
    return midPricesForLegs.map((midPrice, index) => (
      <TableRow key={`leg-$-${index}`}>
        <TableCell className="nested-row">
          {numberFormatter(midPrice, 3)}
        </TableCell>
      </TableRow>
    ));
  };

  const renderNetCreditDebit = (order) => {
    const netCreditOrDebit = getNetCreditOrDebit(order.legs);
    const isCredit = netCreditOrDebit > 0;
    const formattedValue = isCredit
      ? `-$${Math.abs(netCreditOrDebit).toFixed(3)} DR`
      : `+$${Math.abs(netCreditOrDebit).toFixed(3)} CR`;

    return (
      <p
        className={
          isCredit
            ? "text-mui-primary-red-light"
            : "text-mui-green-text-success"
        }
      >
        {formattedValue}
      </p>
    );
  };

  const renderProfitLoss = (isMultiLeg, order, primaryOrder, midPriceForSingle, isStraddle) => {
    if (isMultiLeg && !isStraddle) {
      const profitLoss = calculateProfitLossForMulti(order);
      const isPositive = !isNaN(profitLoss) && profitLoss > 0;
      const formattedProfitLoss = !isNaN(profitLoss)
        ? numberFormatter(profitLoss)
        : "";

      return (
        <p
          className={
            isPositive
              ? "text-mui-green-text-success"
              : "text-mui-primary-red-light"
          }
        >
          {isPositive ? `+${formattedProfitLoss}` : `${formattedProfitLoss}`}
        </p>
      );
    }
    else if (isStraddle) {
      const rawProfitLoss = calculateProfitLossForStraddle(order);
      const profitLossClass = rawProfitLoss > 0
        ? "text-mui-primary-red-light"
        : "text-mui-green-text-success";

      return (
        <p className={profitLossClass}>
          {numberFormatter(rawProfitLoss)}
        </p>
      );
    }
    else {
      const rawProfitLoss = calculateProfitLossForSingle(primaryOrder || order, midPriceForSingle);
      const formattedProfitLoss = rawProfitLoss;
      const profitLossClass = formattedProfitLoss > 0
        ? "text-mui-green-text-success"
        : "text-mui-primary-red-light";

      return (
        <p className={profitLossClass}>
          {numberFormatter(formattedProfitLoss)}
        </p>
      );
    }
  };
  const getOrderValue = (isMultiLeg, order, associatedTrade, primaryOrder, midPriceForSingle, isStraddle) => {
    if (isMultiLeg && !isStraddle) {
      const value = calculateValueForMultiLeg(order, associatedTrade);
      return !isNaN(value) ? numberFormatter(value) : "";
    }
    else if (isStraddle) {
      const sumOfMidPrices = order.legs
        .map(leg => getMarketPrice(leg.option_symbol, leg.side))
        .reduce((acc, midPrice) => acc + midPrice, 0);
      return !isNaN(sumOfMidPrices) ? numberFormatter(sumOfMidPrices * order.total_quantity * 100) : "";
    }
    else {
      // const cost = associatedTrade?.preview_result?.cost || 0; // 7395.4
      // const total = associatedTrade?.preview_result?.orders[0]?.margin_change > 0 // 7544.7
      //   ? cost + calculateProfitLossForSingle(primaryOrder || order, midPriceForSingle)
      //   : getCurrentContractPriceForSingle(primaryOrder || order);
      // return !isNaN(total) ? numberFormatter(total) : "";
      const cost = associatedTrade?.preview_result?.cost ?? 0; // Use nullish coalescing to handle undefined
      const marginChange = associatedTrade?.preview_result?.orders?.[0]?.margin_change ?? 0; // Use default 0 for margin_change
      const total = marginChange > 0
        ? cost + calculateProfitLossForSingle(primaryOrder || order, midPriceForSingle)
        : getCurrentContractPriceForSingle(primaryOrder || order);

      return !isNaN(total) ? numberFormatter(total) : "";
    }
  };

  const handleClosePosBtnClick = () => {
    if (!isWithinMarketHours()) {
      setModalState({ type: "notInMarketHours", isOpen: true });
      return;
    }
    setModalState({ type: "closePosition", isOpen: true });
  };

  return (
    <>
      {modalState.isOpen && modalState.type === "closePosition" && (
        <ModalParent
          open={modalState.isOpen}
          onClose={() => setModalState({ type: null, isOpen: false })}
          orderToClose={selectedPositionInfo.selectedPosition}
          positionType="close"
          selectedTrade={findTradeByOrderId(selectedPositionInfo.parentId)}
        />
      )}
      {modalState.isOpen && modalState.type === "notInMarketHours" && (
        <NotInMarketHoursModal
          open={modalState.isOpen}
          onClose={() => setModalState({ type: null, isOpen: false })}
          isClosingPosition={true}
        />
      )}
      {filledOrders.map((order) => {
        const isMultiLeg = isMultiLegOrder(order);
        const isLimit = isLimitOrder(order);
        const associatedTrade = findTradeByOrderId(order.external_order_id);
        const primaryOrder = isLimit ? getPrimaryOrderForLimitOrders(order.legs) : null;
        const optionSymbol = primaryOrder ? primaryOrder?.option_symbol : order.option_symbol;
        const isStraddle = associatedTrade.trade_idea?.option_strategy === TradeStrategies.Straddle;

        const midPriceForSingle = getMarketPrice(optionSymbol, order.side || primaryOrder?.side);

        return (
          <TableRow key={order.id} className="body2">
            <TableCell id="strategy">
              {renderStrategyCell(order, isMultiLeg, associatedTrade)}
            </TableCell>
            <TableCell id="contract">
              {renderContractType(isMultiLeg, order, primaryOrder)}
            </TableCell>
            <TableCell id="qty">
              {renderQty(isMultiLeg, order, primaryOrder)}
            </TableCell>
            <TableCell id="midPrice">
              {!isMultiLeg && renderMidPrice(midPriceForSingle)}
              {isStraddle && renderMidPriceForStraddle(order.legs)}
              {isMultiLeg && !isStraddle && "—"}
            </TableCell>
            <TableCell id="netCreditDebit">
              {isMultiLeg && !isStraddle
                ? (
                    <TableRow key={`leg-${order.id}`}>
                      <TableCell className="nested-row">
                        {renderNetCreditDebit(order)}
                      </TableCell>
                    </TableRow>
                  )
                : (
                    "—"
                  )}
            </TableCell>
            <TableCell id="profitloss">
              {renderProfitLoss(isMultiLeg, order, primaryOrder, midPriceForSingle, isStraddle)}
            </TableCell>
            <TableCell id="orderValue">
              {getOrderValue(isMultiLeg, order, associatedTrade, primaryOrder, midPriceForSingle, isStraddle)}
            </TableCell>

            <TableCell id="sltp">
              {isLimit && order.legs
                ? (
                    <StatusChip
                      status={`${numberFormatter(associatedTrade.trade_idea?.stop || 0)} / ${numberFormatter(associatedTrade.trade_idea?.profit || 0)}`}
                    />
                  )
                : (
                    "N/A"
                  )}
            </TableCell>

            <TableCell>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                className="tradesButton-submit"
                disabled={associatedTrade.trade_idea?.position === "close"}
                onClick={() => {
                  if (primaryOrder) {
                    setSelectedPositionInfo({
                      selectedPosition: primaryOrder,
                      parentId: primaryOrder.parent_id,
                    });
                  }
                  else {
                    setSelectedPositionInfo({
                      selectedPosition: order,
                      parentId: order.external_order_id,
                    });
                  }
                  // setSelectedPositionInfo(primaryOrder || order);
                  // setShowClosePositionModal(true);
                  handleClosePosBtnClick();
                }}
              >
                Close Position
              </Button>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default OpenPositions;
