import { numberFormatter } from "./helpers";
import OptionDetails from "./OptionDetails";

const buySellOptions = {
  buy: "buy",
  sell: "sell",
} as const;

const closePositionLabels = ["bidask", "premium"];
const sharedSingleLegLabels = ["premium", "probabilityOfProfit", "maxLoss", "stopLoss", "maxProfit", "takeProfit", "orderType", "timeInForce", "action", "contractType"];

function LegContent({ optionContent, optionQuotes = [], isMarket = false, quantity = 0, selectedTrade, isClosePosition = false }) {
  const getContractProceedsOrCosts = (buyOrSell, index) => {
    const optionQuote = optionQuotes[index] || {};
    const bid = isNaN(optionQuote.bid) ? 0 : optionQuote.bid;
    const ask = isNaN(optionQuote.ask) ? 0 : optionQuote.ask;
    const validQuantity = isNaN(quantity) ? 0 : quantity;

    const numberFormat = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    let result;
    if (buyOrSell === "sell") {
      const contractProceeds = bid * 100 * validQuantity;
      result = numberFormat.format(contractProceeds);
    }
    else {
      const contractCost = ask * 100 * validQuantity;
      result = numberFormat.format(contractCost);
    }
    return result;
  };

  const endIndex = sharedSingleLegLabels.length - 2;
  const beforeLastTwo = sharedSingleLegLabels.slice(0, endIndex);
  const lastTwo = sharedSingleLegLabels.slice(-2);

  let addLabelsForFooter;

  if (isClosePosition) {
    addLabelsForFooter = closePositionLabels;
  }
  else if (isMarket) {
    addLabelsForFooter = [...beforeLastTwo, "bidask", ...lastTwo];
  }
  else {
    addLabelsForFooter = [...beforeLastTwo, "limit", ...lastTwo];
  }
  const isMultiLeg = selectedTrade?.trade_idea.legs.length > 1;
  const isSingleLeg = selectedTrade?.trade_idea.legs.length === 1;
  return (
    <div className="flex flex-col gap-y-6">
      {isMultiLeg && (
        <div className="w-full flex justify-between gap-x-6 body2">
          {selectedTrade?.trade_idea?.legs?.map((leg, index) => (
            <div key={index} className="flex flex-col flex-1 justify-center gap-x-6">
              {!isClosePosition && (
                <>
                  <div className="flex flex-1 justify-between pb-2">
                    <p>Contract Premium</p>
                    <p className="tabular-nums">
                      $
                      {leg.premium}
                    </p>
                  </div>
                  <div className="flex flex-1 justify-between pb-2">
                    <p>{leg.buy_sell === buySellOptions.sell ? "Contract Proceeds" : "Contract Cost"}</p>
                    <p className="tabular-nums">
                      {getContractProceedsOrCosts(leg.buy_sell, index)}
                    </p>
                  </div>
                </>
              )}
              <div className="flex flex-1 justify-between pb-2">
                <div className="flex gap-x-1">
                  <p className="text-mui-green-text-success">Bid</p>
                  <span>/</span>
                  <p className="text-mui-red-success">Ask</p>
                </div>
                <div key={index} className="flex items-center gap-x-1">
                  <span className="text-mui-green-text-success">
                    {numberFormatter(optionQuotes[index]?.bid)}
                  </span>
                  <span>/</span>
                  <span className="text-mui-red-success">
                    {numberFormatter(optionQuotes[index]?.ask)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {isSingleLeg && !isClosePosition && (
        <OptionDetails optionContent={optionContent} footerLabels={addLabelsForFooter} isSingleLeg={true} />
      )}
      {isSingleLeg && isClosePosition && (
        <div className="grid grid-cols-2 gap-y-2 gap-x-6 body2 tabular-nums">
          <div className="flex flex-1 justify-between pb-2">
            <div className="flex gap-x-1">
              <p className="text-mui-green-text-success">Bid</p>
              <span>/</span>
              <p className="text-mui-red-success">Ask</p>
            </div>
            <div className="flex items-center gap-x-1">
              <span className="text-mui-green-text-success">
                {numberFormatter(optionQuotes[0]?.bid)}
              </span>
              <span>/</span>
              <span className="text-mui-red-success">
                {numberFormatter(optionQuotes[0]?.ask)}
              </span>
            </div>
          </div>
          <div className="flex flex-1 justify-between pb-2">
            <div>Premium (Mid Price):</div>
            <div>
              {isNaN(optionQuotes[0]?.bid)
                ? ""
                : numberFormatter((optionQuotes[0]?.bid + optionQuotes[0]?.ask) / 2)}
            </div>

          </div>
        </div>
      )}
    </div>
  );
}
export default LegContent;
