import { Button } from "@mui/material";
import { ReactComponent as CircledCheck } from "../../assets/icons/CircledCheck.svg";
import { ReactComponent as InfoToolTip } from "../../assets/icons/InfoTooltip.svg";
import { formatDate, mapErrorMessage } from "./helpers";
import { ReactComponent as RightArrow } from "../../assets/icons/TradeIdeaModal/RightArrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/TradeIdeaModal/LeftArrow.svg";
import { useNavigate } from "react-router-dom";
import { getUserTrades } from "../../redux/slices/tradeOrdersSlice";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";

type OverlayMessageProps = {
  closeExecutedOverlay?: () => void;
  closeErrorOverlay?: () => void;
  errorMessage?: string | null;
  errorType?: string;
  isClosing?: boolean;
};

enum OverlayMessageType {
  Preview = "preview",
  Execute = "execute",
  AfterHours = "afterhours",
}
const OverlayMessage = ({
  closeExecutedOverlay,
  closeErrorOverlay,
  errorMessage = "",
  errorType,
  isClosing = false,
}: OverlayMessageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleCloseOverlay = async () => {
    if (isClosing) {
      await dispatch(getUserTrades({ vendor: "tradier" }));
    }
    if (!errorMessage && !errorType) {
      closeExecutedOverlay();
    }
    if (errorType) {
      closeErrorOverlay();
    }
  };

  const handleNavigate = async (tab: number) => {
    if (isClosing) {
      await dispatch(getUserTrades({ vendor: "tradier" }));
    }
    navigate("/performance-dashboard", { state: { tab } });
  };

  let userFriendlyError: string;
  if (errorType === OverlayMessageType.AfterHours) {
    userFriendlyError = "Regular trading hours have ended — please try again when the market opens.";
  }
  else {
    userFriendlyError = mapErrorMessage(errorMessage);
  }

  return (
    <div
      className="absolute inset-0 flex flex-col gap-y-4 items-center justify-center bg-black bg-opacity-70 z-50 backdrop-blur min-h-[522px]"
      style={{ top: "50%", transform: "translateY(-50%)", height: "100%" }}
      onClick={handleCloseOverlay}
    >
      {!errorMessage && !errorType
        ? (
            <div className="flex flex-col items-center justify-center gap-y-4 p-6 rounded-lg">
              <CircledCheck className="text-mui-green-text-success" />
              <h6 className="text-white text-center">
                This order has been executed on
                {" "}
                {formatDate(new Date())}
                .
              </h6>
              <Button
                variant="contained"
                color="inherit"
                size="large"
                className="whiteButton"
                onClick={() => handleNavigate(1)}
              >
                View order in Performance Dashboard
                <RightArrow className="ms-2" />
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="inherit"
                className="whiteButton-outlined"
                onClick={isClosing ? () => handleNavigate(2) : handleCloseOverlay}
              >
                <LeftArrow className="me-2" />
                {isClosing ? "Back to Order History" : "Back to Trade Ideas" }
              </Button>
            </div>
          )
        : (
            <div className="flex flex-col items-center gap-y-4">
              <div className="flex gap-x-3 bg-mui-alert-error-pink p-4 rounded w-[90%]">
                <InfoToolTip className=" text-mui-primary-red-light w-5 h-5 flex-shrink-0" />
                <div className="flex flex-col text-mui-alert-error-text flex-1">
                  <div className=" body1">
                    {errorType === OverlayMessageType.Preview && "Unable to preview"}
                    {errorType === OverlayMessageType.Execute && "Unable to execute"}
                    {errorType === OverlayMessageType.AfterHours && "Outside Regular Trading Hours"}
                  </div>
                  <div className="body2">
                    {userFriendlyError}
                  </div>
                </div>
              </div>
              <div className="body2 text-white">Click to close</div>
            </div>
          )}
    </div>
  );
};

export default OverlayMessage;
