import { Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as PositionIcon } from "../../assets/icons/PerformanceDashboard/PositionIcon.svg";
import { ReactComponent as OpenOrderIcon } from "../../assets/icons/PerformanceDashboard/OpenOrderIcon.svg";
import { ReactComponent as OrderHistoryIcon } from "../../assets/icons/PerformanceDashboard/OrderHistoryIcon.svg";
import OrdersTable from "./OrdersTable";
import { useDispatch, useSelector } from "react-redux";
import { getUserPositions } from "../../redux/slices/tradeOrdersSlice";
import { AppDispatch } from "../../redux/store";
import { numberFormatter } from "../OptionsTrading/helpers";
import { useLocation } from "react-router-dom";

const tabData = [
  { icon: PositionIcon, text: "Open Positions" },
  { icon: OpenOrderIcon, text: "Open Orders" },
  { icon: OrderHistoryIcon, text: "Order History" },
];

export type Balance = {
  netAccountValue: number;
  portfolioValue: number;
  profitLoss: number;
  buyingPower: number;
};

function PerformanceDashboard() {
  const location = useLocation();
  const [value, setValue] = useState(location.state?.tab || 0);
  const dispatch = useDispatch<AppDispatch>();
  const [balance, setBalance] = useState<Balance>({
    netAccountValue: 0,
    portfolioValue: 0,
    profitLoss: 0,
    buyingPower: 0,
  });
  const [openOrdersSymbols, setOpenOrdersSymbols] = useState([]);
  const virtualAccount = useSelector(
    (state: { tradierAccount }) => state.tradierAccount.account,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigateTab = (tabValue) => {
    setValue(tabValue);
  };

  useEffect(() => {
    if (location.state?.tab !== undefined) {
      setValue(location.state.tab);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchPositions = async () => {
      if (virtualAccount?.external_id) {
        const { payload } = await dispatch(getUserPositions({
          vendor: "tradier",
          accountId: virtualAccount.external_id,
        }));
        const balances = payload?.balances;
        const symbols = payload?.positions.map(position => position.symbol);
        setOpenOrdersSymbols(symbols);
        setBalance({
          netAccountValue: balances?.total_equity,
          profitLoss: balances?.open_pl,
          portfolioValue: balances?.market_value,
          buyingPower: balances?.pdt?.option_buying_power || balances?.margin?.option_buying_power,
        });
      }
    };
    fetchPositions();
  }, [dispatch, virtualAccount?.external_id]);
  return (
    <div className="bg-tgpt-black-50 h-screen">
      <div className="flex flex-col h-full p-6 gap-y-6">
        <div className="flex flex-col gap-y-6">
          <h5>Overall Performance</h5>
          <DataDisplaySection balance={balance} />
        </div>
        <div className="flex-grow flex flex-col overflow-hidden bg-white rounded shadow ">
          <TabSection value={value} onChange={handleChange} navigateTab={navigateTab} openOrdersSymbols={openOrdersSymbols} />
        </div>
      </div>
    </div>

  );
};
const DataDisplaySection = ({ balance }) => {
  return (
    <section className="flex gap-x-6">
      <DataCard
        title="Net Account Value"
        value={numberFormatter(balance?.netAccountValue)}
      />

      <DataCard
        title="Buying Power"
        value={numberFormatter(balance?.buyingPower)}
      />
    </section>
  );
};

const DataCard = ({ title, value }) => (
  <div className="bg-white rounded p-4 flex flex-1 flex-col gap-y-2 shadow">
    <p className="subtitle text-mui-black-60">{title}</p>
    <h5 className="tabular-nums text-mui-black-87">{value}</h5>
  </div>
);

const TabSection = ({ value, onChange, navigateTab, openOrdersSymbols }) => (
  <>
    <Tabs value={value} onChange={onChange} className="tabs-item" aria-label="tabs">
      {tabData.map((tab, index) => (
        <Tab
          key={index}
          value={index}
          label={(
            <TabLabel
              icon={tab.icon}
              text={tab.text}
              selected={value === index}
            />
          )}
        />
      ))}
    </Tabs>
    <OrdersTable value={value} navigateTab={navigateTab} openOrdersSymbols={openOrdersSymbols} />
  </>
);

const TabLabel = ({ icon: Icon, text, selected }) => (
  <div className="flex items-center gap-x-2">
    <Icon className={selected ? "fill-blue-400" : "fill-current"} />
    <Typography variant="body2">{text}</Typography>
  </div>
);
export default PerformanceDashboard;
