import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactComponent as CrossIcon } from "../../assets/icons/CrossIcon.svg";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  resetRemoveList,
  resetReorderList,
  setWatchlistDrawerState,
} from "../../redux/slices/watchlistSlice";
import { AppDispatch } from "../../redux/store";

type DialogProps = {
  isOpen: boolean;
  onCloseModal: () => void;
};
const Modal = ({ isOpen, onCloseModal }: DialogProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const onClickDiscard = useCallback(() => {
    dispatch(resetRemoveList());
    dispatch(resetReorderList());
    onCloseModal();
    dispatch(setWatchlistDrawerState(false));
  }, [resetRemoveList, resetReorderList, onCloseModal, dispatch]);

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseModal}
      className="dialog-model"
      aria-describedby="discard-dialog"
    >
      <DialogTitle className="dialog-title relative flex justify-between">
        <h6 className="font-medium">You have unsaved changes.</h6>
        <IconButton size="small" onClick={onCloseModal} className="cross-icon">
          <CrossIcon className="fill-current" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <DialogContentText
          id="alert-dialog-slide-description"
          className="dialog-text"
        >
          <p className="body2">
            Unsaved changes in your watchlist will be discarded. Are you sure?
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-buttons">
        <Button
          variant="outlined"
          className="button-outlined"
          onClick={onCloseModal}
        >
          Cancel
        </Button>
        <Button
          onClick={onClickDiscard}
          variant="contained"
          className="button-danger"
          color="error"
        >
          Discard changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
