import { useDispatch } from "react-redux";
import { setIsHighlightBadge } from "../../redux/slices/watchlistSlice";
import { useRef } from "react";

export const useIncrementTrigger = () => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);

  const updateBadgeCount = () => {
    dispatch(setIsHighlightBadge(true));
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      dispatch(setIsHighlightBadge(false));
      timerRef.current = null;
    }, 3000);
  };

  return { updateBadgeCount };
};
