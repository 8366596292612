import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface getTradesArgs {
  vendor: string;
  accountId?: string;
}

const initialState = {
  error: null,
  orders: [],
  trades: [],
  positions: [],
  isLoading: false,
};

export const getUserTrades = createAsyncThunk(
  "tradeOrders/getTradesByUser",
  async (arg: getTradesArgs, { rejectWithValue }) => {
    try {
      console.log("getting updated trades...");
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-get-trades/${arg.vendor}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      console.log("new trades", data);
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.log("error: ", error.response);
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const getUserPositions = createAsyncThunk(
  "tradeOrders/getPositions",
  async (arg: getTradesArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/${arg.vendor}/accounts/${arg.accountId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.log("error: ", error.response);
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const tradeOrdersSlice = createSlice({
  name: "tradeOrders",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTrades.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getUserTrades.fulfilled, (state, action) => {
        const trades = [];
        const orders = [];
        action.payload.forEach((item) => {
          if (item.trade) trades.push(item.trade);
          if (item.order) orders.push(item.order);
        });
        state.trades = trades;
        state.orders = orders;
        state.isLoading = false;
      })
      .addCase(getUserTrades.rejected, (state, action) => {
        state.error = action.payload || "Failed to fetch trades";
        state.isLoading = false;
      })
      .addCase(getUserPositions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserPositions.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserPositions.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default tradeOrdersSlice.reducer;
