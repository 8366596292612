import { Box, Button, Chip, Collapse, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { convertOptionSymbolToContract, numberFormatter } from "../OptionsTrading/helpers";
import { StatusChip } from "./StatusChip";
import { isLimitOrder, isMultiLegOrder } from "./OrdersTable";
import { useEffect, useState } from "react";
import { Order, Sides, TradeIdea } from "../../assets/interfaces/interfaces";
import { getPrimaryOrderForLimitOrders } from "./OpenPositions";
import CancelOrderModal from "./CancelOrderModal";

export const renderStrategyCell = (
  order: Order,
  isMultiLeg: boolean,
  associatedTrade: TradeIdea,
  isLimit?: boolean,
  handleExpandClick?: (id: string) => void,
  openRowId?: string | null,
) => {
  return (
    <div className="flex">
      {isLimit && order.legs && (
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => handleExpandClick(order.id)}
          className="w-10 h-10 rounded-full hover:bg-slate-100"
        >
          {openRowId === order.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      )}
      <div>
        <div className="caption">
          {isMultiLeg ? "Multi Leg • " : "Single Leg • "}
          {associatedTrade?.trade_idea?.symbol}
        </div>
        <div className="body2">
          {associatedTrade?.trade_idea?.option_strategy}
        </div>
      </div>
    </div>
  );
};

export const renderContractType = (
  isMultiLeg: boolean,
  order: Order,
  primaryOrder: Order,
  isSecondaryOrder?: boolean,
) => {
  if (isMultiLeg) {
    return order.legs?.map((leg, index) => {
      const contractDetails = convertOptionSymbolToContract(leg.option_symbol);
      return (
        <TableRow key={`leg-${order.external_order_id}-${index}`}>
          <TableCell className="nested-row">
            <div className="flex flex-col gap-y-1">
              {contractDetails.type}
              <br />
              {contractDetails.expiry}
              <Chip
                size="small"
                className="dashboard-chip pt-1"
                variant="outlined"
                label={Sides[leg.side]}
              />
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }
  else {
    const contractDetails = convertOptionSymbolToContract(primaryOrder ? primaryOrder.option_symbol : order.option_symbol);
    return (
      <div className="flex flex-col gap-y-2">
        <div>
          {contractDetails.type}
          <br />
          {contractDetails.expiry}
        </div>
        <Chip
          size="small"
          className="dashboard-chip"
          variant="outlined"
          label={isSecondaryOrder ? order.type === "stop" ? "Stop Loss" : "Take Profit" : Sides[primaryOrder ? primaryOrder.side : order.side]}
        />
      </div>
    );
  }
};

export const renderQuantity = (
  isMultiLeg: boolean,
  order: Order,
  primaryOrder: Order,
) => {
  if (isMultiLeg) {
    if (!order.legs) {
      console.error("Error: No legs found in the order.");
      return null;
    }

    return order.legs.map((leg, index) => (
      <TableRow key={`leg-${order.id}-${index}`}>
        <TableCell className="nested-row">
          {leg.exec_quantity}
          {" "}
          /
          {" "}
          {leg.remaining_quantity + leg.exec_quantity}
        </TableCell>
      </TableRow>
    ));
  }
  const execQuantity = primaryOrder ? primaryOrder.exec_quantity : order.exec_quantity;
  const remainingQuantity = (primaryOrder ? primaryOrder.remaining_quantity : order.remaining_quantity) + execQuantity;

  return `${execQuantity} / ${remainingQuantity}`;
};

export const renderStatus = (
  isMultiLeg: boolean,
  order: Order,
  primaryOrder: Order,
) => {
  if (isMultiLeg) {
    if (!order.legs) {
      console.error("Error: No legs found in the order.");
      return null;
    }

    return order.legs.map((leg, index) => (
      <TableRow key={`leg-${order.id}-${index}`}>
        <TableCell className="nested-row">
          <StatusChip status={leg.status} />
        </TableCell>
      </TableRow>
    ));
  }

  const status = primaryOrder ? primaryOrder.status : order.status;

  if (!status) {
    console.error("Error: No status found.");
    return null;
  }

  return <StatusChip status={status} />;
};

const OpenOrders = ({ pendingOrders, navigateTab, findTradeByOrderId }) => {
  const [openRowId, setOpenRowId] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [isSecondary, setIsSecondary] = useState(false);
  const [secondaryOrderToCancel, setSecondaryOrderToCancel] = useState(null);

  useEffect(() => {
    setIsSecondary(false);
  }, []);

  const handleExpandClick = (orderId) => {
    setOpenRowId(prevId => prevId === orderId ? null : orderId);
  };

  console.log("pending orders", pendingOrders);
  const calculateUnitPrice = (
    associatedTrade,
    order,
    primaryOrder,
  ): number => {
    if (isLimitOrder(order)) {
      const price = primaryOrder
        ? primaryOrder.price * 100
        : order.price * 100;
      return price;
    }
    else {
      return associatedTrade?.preview_result?.cost / (order.exec_quantity + order.remaining_quantity);
    }
  };

  return (
    <>
      {pendingOrders.map((order) => {
        const isMultiLeg = isMultiLegOrder(order);
        const isLimit = isLimitOrder(order);
        let primaryOrder = null;
        if (isLimit) {
          if (order.legs && order.legs.length > 0) {
            primaryOrder = getPrimaryOrderForLimitOrders(order.legs);
          }
          else {
            primaryOrder = order;
          }
        }
        const associatedTrade = findTradeByOrderId(order.external_order_id);
        return (
          <>
            { showCancelModal && (
              <CancelOrderModal
                open={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                isSecondary={isSecondary}
                order={isSecondary ? secondaryOrderToCancel : orderToCancel}
              />
            )}
            <TableRow key={order.id} className="body2">
              <TableCell id="strategy">
                {renderStrategyCell(order, isMultiLeg, associatedTrade, isLimit, handleExpandClick, openRowId)}
              </TableCell>
              <TableCell id="contractType">
                {renderContractType(isMultiLeg, order, primaryOrder)}
              </TableCell>
              <TableCell id="qty">
                {renderQuantity(isMultiLeg, order, primaryOrder)}
              </TableCell>
              <TableCell id="status">
                {renderStatus(isMultiLeg, order, primaryOrder)}
              </TableCell>
              <TableCell id="unitPrice">
                {numberFormatter(calculateUnitPrice(associatedTrade, order, primaryOrder))}
              </TableCell>
              <TableCell id="totalCost">{numberFormatter(associatedTrade?.preview_result?.cost)}</TableCell>
              <TableCell id="orderType">{isLimit ? "Limit" : "Market"}</TableCell>
              <TableCell id="cancel">
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  className="tradesButton-submit"
                  onClick={() => {
                    setShowCancelModal(true);
                    setIsSecondary(false);
                    setOrderToCancel(order);
                  }}
                >
                  Cancel
                </Button>
              </TableCell>
            </TableRow>
            {isLimit && openRowId === order.id && (
              <>
                <TableRow className="collapsible-row-background">
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={openRowId === order.id} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Table>
                          <TableBody>
                            {order.legs
                              ?.filter(leg => leg !== primaryOrder)
                              .map((leg, index) => (
                                <TableRow key={`leg-${associatedTrade.id}-${index}`}>
                                  <TableCell style={{ width: "15%" }} className="collapsible-row"></TableCell>
                                  <TableCell style={{ width: "11.5%" }} className="collapsible-row"></TableCell>
                                  <TableCell style={{ width: "13%" }} id="qty" className="collapsible-row">
                                    {leg.exec_quantity}
                                    {" "}
                                    /
                                    {" "}
                                    {leg.exec_quantity + leg.remaining_quantity}
                                  </TableCell>
                                  <TableCell style={{ width: "13%" }} id="status" className="collapsible-row">
                                    <StatusChip status={leg.status} handleStatusClick={navigateTab} />
                                  </TableCell>
                                  <TableCell style={{ width: "9%" }} id="unitPrice" className="collapsible-row">
                                    {leg.type === "stop" ? numberFormatter(leg.stop_price * 100) : numberFormatter(leg.price * 100)}
                                  </TableCell>
                                  <TableCell style={{ width: "12%" }} id="estCost" className="collapsible-row">—</TableCell>
                                  <TableCell style={{ width: "10%" }} id="orderType" className="collapsible-row">
                                    {leg.type === "stop" ? "Stop Loss" : "Take Profit"}
                                  </TableCell>
                                  <TableCell style={{ width: "11%" }} id="cancel" className="collapsible-row">
                                    <Button
                                      variant="outlined"
                                      color="inherit"
                                      size="small"
                                      className="tradesButton-outlined"
                                      onClick={() => {
                                        setShowCancelModal(true);
                                        setIsSecondary(true);
                                        setSecondaryOrderToCancel(leg);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            )}
          </>
        );
      })}
    </>
  );
};

export default OpenOrders;
