import axios from "axios";
import { useState, useEffect, useCallback } from "react";

const usePolling = (url, interval, token = null) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(url, {
        method: "GET",
        headers: {
          // Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        withCredentials: true,
      });
      setData(response.data);
    }
    catch (err) {
      setError(err);
    }
    finally {
      setLoading(false);
    }
  }, [url, token]);

  useEffect(() => {
    fetchData();
    const id = setInterval(fetchData, interval);

    return () => clearInterval(id);
  }, [fetchData, interval]);

  return { data, loading, error };
};

export default usePolling;
