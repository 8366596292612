import axios from "axios";

export const fetchSparkline = async (ticker, fromTime, toTime, signal) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SPL_URL}/aggs/ticker/${ticker}?resolution=1&timespan=hour&fromTime=${fromTime}&toTime=${toTime}`,
    {
      withCredentials: true,
      signal,
    },
  );

  if (!data || Object.keys(data).length === 0) {
    return;
  }
  return data;
};
