import { ReactComponent as InfoToolTip } from "../../assets/icons/InfoTooltip.svg";
import { ReactComponent as CircledCheckOutlined } from "../../assets/icons/TradeIdeaModal/CircledCheckOutlined.svg";
import { ReactComponent as TriangleWarning } from "../../assets/icons/TradeIdeaModal/TriangleWarning.svg";

export const SuccessMessage = ({ message }) => {
  return (
    <div className="bg-mui-green-text-success-08 bg-opacity-[0.08px] p-2 rounded-md flex justify-center items-center gap-x-2">
      <CircledCheckOutlined />
      <div className="body1 text-mui-green-text-success font-medium">{message}</div>
    </div>
  );
};

export const ErrorMessage = ({ message }) => {
  return (
    <div className="bg-mui-alert-error-pink bg-opacity-[0.08px] p-2 rounded-md flex justify-center items-center gap-x-2">
      <InfoToolTip className="text-mui-red-success w-5 h-5" />
      <div className="body1 text-mui-red-success font-medium">{message}</div>
    </div>
  );
};

export const WarningMessage = ({ message }) => {
  return (
    <div className="bg-mui-warning-main/[.08] p-2 rounded-md flex justify-center items-center gap-x-2">
      <TriangleWarning />
      <div className="body1 text-mui-warning-main font-medium">
        {message}
      </div>
    </div>
  );
};
