import { useEffect, useState, useCallback, useRef } from "react";
import { Ticker } from "../../assets/interfaces/interfaces";
import { numberFormatter } from "../../helpers";
import { Button, CircularProgress, IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { MODE } from "./constants";
import CrossSVG from "../Svgs/cross";
import SparklineChart from "../Shared/Sparkline";
import moment from "moment";
import { fetchSparkline } from "../api/fetchSparkline";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { removeTicker, setWatchlistDrawerState } from "../../redux/slices/watchlistSlice";
import { ReactComponent as DragHandle } from "../../assets/icons/DragHandle.svg";
import { PromptList, getPrompt } from "./constants";
import "./index.scss";
import { ReactComponent as PlusFilledCircle } from "../../assets/icons/PlusFilledCircle.svg";
import { ReactComponent as CheckFilledCircle } from "../../assets/icons/CheckFilledCircle.svg";
import { fetchCompanyData } from "../api/fetchCompanyData";
import { RootState } from "../../assets/interfaces/interfaces";

type TickerProps = {
  ticker: Ticker;
  isSelected: boolean;
  mode: `${MODE}`;
  currentPrice: number;
  className?: string;
  onDragOver: (e, id: string) => void;
  onDrop: (e, id: string) => void;
  onDragStart: (e, id: string) => void;
  onDragLeave: () => void;
  handlePrompt: (msg: string) => void;
  onAddTicker: (string) => void;
};

export default function TickerCard({
  ticker,
  isSelected,
  currentPrice,
  mode,
  className,
  onDragStart,
  onDrop,
  onDragOver,
  handlePrompt,
  onDragLeave,
  onAddTicker,
}: TickerProps) {
  const [series, setSeries] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const { isMutating, tickers } = useSelector(
    (state: { watchlist }) => state.watchlist,
  );
  const isMessageStreaming = useSelector((state: RootState) => state?.finchat?.loading);
  const [isTickerInWatchlist, setIsTickerInWatchlist] = useState(
    tickers.some(existingTicker => existingTicker.ticker === ticker?.ticker) || false,
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [prevClosePrice, setPrevClosePrice] = useState(0);
  const [companyName, setCompanyName] = useState("");

  const handleButtonClick = useCallback(
    (promptType, tickerName) => {
      const prompt = getPrompt(promptType, tickerName);
      handlePrompt(prompt);
      if (isMobile) {
        dispatch(setWatchlistDrawerState(false));
      }
    },
    [handlePrompt],
  );

  const fetchSparklineData = async (signal) => {
    const toTime = moment().format("YYYY-MM-DD");
    const fromTime = moment().subtract(7, "days").format("YYYY-MM-DD");

    try {
      const data = await fetchSparkline(ticker?.ticker, fromTime, toTime, signal);
      if (data && data.results) {
        const seriesForSparkline = data.results
          .slice(0, 16)
          .map(result => result.c)
          .reverse();
        if (Array.isArray(seriesForSparkline) && seriesForSparkline.length >= 16) {
          setSeries(seriesForSparkline);
        }
        else {
          setSeries("Data unavailable");
        }
      }
      else {
        setSeries("Data unavailable");
      }
    }
    catch (error) {
      if (error.name !== "CanceledError") {
        console.error("Error fetching sparkline data:", error);
      }
    }
  };

  const getCompanyData = async (signal) => {
    try {
      const { tickerDetails, secPrice } = await fetchCompanyData(ticker?.ticker, signal);
      return { name: tickerDetails?.name, prev_close_price: secPrice?.prev_close_price };
    }
    catch (error) {
      if (error.name !== "CanceledError") {
        console.error("Error fetching company data:", error);
      }
      return null;
    }
  };

  const getPriceAndName = async (signal) => {
    const companyData = await getCompanyData(signal);
    if (companyData) {
      const { name, prev_close_price } = companyData;
      setCompanyName(name);
      setPrevClosePrice(prev_close_price);
    }
  };

  const priceChange = currentPrice - prevClosePrice;

  const onClickDelete = useCallback((e, id: string) => {
    e.preventDefault();
    dispatch(removeTicker(id));
  }, []);

  const onClickAddTicker = useCallback(async () => {
    if (isTickerInWatchlist) {
      return;
    }
    setIsTickerInWatchlist(true); // Optimistic UI update
    try {
      await onAddTicker(ticker?.ticker);
    }
    catch (e) {
      console.error("Error adding ticker to watchlist:", e);
      setIsTickerInWatchlist(false);
    }
  }, [onAddTicker, ticker]);

  const tickerRef = useRef(null);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchDataIfVisible = async ([entry]) => {
      if (entry.isIntersecting) {
        if (tickerRef.current) {
          observer.unobserve(tickerRef.current); // can stop observing the ref once fetching starts
        }

        await fetchSparklineData(signal);
        await getPriceAndName(signal);
      }
    };

    const observer = new IntersectionObserver(fetchDataIfVisible, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (tickerRef.current) {
      observer.observe(tickerRef.current);
    }

    return () => {
      observer.disconnect();
      abortController.abort();
    };
  }, [ticker]);

  return (
    <div
      ref={tickerRef}
      className={`flex flex-col w-full draggable-item cursor-pointer ${className}`}
      draggable={!isMutating && mode === MODE.LIST_MANAGEMENT}
      onDragOver={e => onDragOver(e, ticker.ticker)}
      onDrop={e => onDrop(e, ticker.ticker)}
      onDragStart={(e) => {
        onDragStart(e, ticker.ticker);
      }}
      onDragLeave={onDragLeave}
    >
      <div className="flex w-full items-center">
        {mode === MODE.LIST_MANAGEMENT && (
          <IconButton
            size="small"
            onClick={e => onClickDelete(e, ticker.ticker)}
            className="cross-icon"
            disableRipple={true}
            sx={{ padding: "0px" }}
            disabled={isMutating}
          >
            <CrossSVG color="#D32F2F" />
          </IconButton>
        )}
        {mode === MODE.SEARCH && (
          <IconButton
            size="small"
            onClick={() => onClickAddTicker()}
            disabled={isTickerInWatchlist}
          >
            {isTickerInWatchlist
              ? (
                  <CheckFilledCircle className="m-1" />
                )
              : (
                  <PlusFilledCircle className="h-5 w-5 fill-current m-1" />
                )}
          </IconButton>
        )}
        <div className="flex justify-between p-1 items-center body1 h-[72px] w-full sm:w-[328px]">
          <div className="flex flex-col w-[90px]">
            <p className="body1 font-semibold">{ticker.ticker}</p>
            <Tooltip title={ticker.name || companyName}>
              <p className="body2 text-mui-black-60 dark:text-mui-white-70 whitespace-nowrap overflow-hidden text-ellipsis">
                {mode === MODE.SEARCH ? ticker.name : companyName}
              </p>

            </Tooltip>
          </div>
          <div>
            {series === null
              ? (
                  <div className="flex justify-center items-center">
                    <CircularProgress size={24} />
                  </div>
                )
              : Array.isArray(series)
                ? <SparklineChart series={series} isDecreasing={priceChange < 0} />
                : (
                    <p className="text-mui-black-60 dark:text-mui-white-70 text-xs text-center">
                      Data
                      <br />
                      Unavailable
                    </p>
                  )}

          </div>
          <div className="flex flex-col w-[90px] items-end">
            <p className="tabular-nums">{numberFormatter(currentPrice)}</p>
            <p className={`${priceChange < 0 ? "text-text-error-main" : "text-text-success-main"} body2 text-right tabular-nums`}>
              {priceChange && prevClosePrice
                ? `${priceChange > 0 ? "+" : ""}${((priceChange / prevClosePrice) * 100).toFixed(2)}%`
                : "0%"}
            </p>
          </div>
        </div>
        {mode === MODE.LIST_MANAGEMENT && (
          <div className="dark:bg-[#363636] h-5 cursor-grab drag-handle">
            <DragHandle />
          </div>
        )}
      </div>

      {isSelected && (mode !== MODE.LIST_MANAGEMENT) && (
        <div className="pb-2 flex flex-wrap gap-1">
          {PromptList.map((type, index) => (
            <Button
              key={index}
              className="button-outlined"
              variant="outlined"
              color="inherit"
              size="small"
              // Disable prompt button when name is not loaded or a prompt query is goingon
              disabled={(!ticker.name && !companyName) || isMessageStreaming}
              onClick={() => handleButtonClick(type, companyName)}

            >
              <p className="tracking-[0.46px]">{type}</p>
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}
