import axios from "axios";

export const fetchCompanyData = async (ticker, signal) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SPL_URL}/company-data/${ticker}`,
    {
      withCredentials: true,
      signal,
    },
  );

  if (Object.keys(data).length === 0) {
    return;
  }
  return data;
};
