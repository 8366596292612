export const DashboardIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9415 3.48728L20.9342 3.46989C20.8968 3.3833 20.8434 3.30219 20.7741 3.23123C20.7001 3.15554 20.6148 3.09843 20.5239 3.0599C20.4329 3.0213 20.3332 3 20.2286 3H17.1429C16.7168 3 16.3714 3.35346 16.3714 3.78947C16.3714 4.22549 16.7168 4.57895 17.1429 4.57895H18.3662L15.9059 7.09675L13.3328 6.21898C13.0187 6.11181 12.6723 6.19549 12.4381 6.43513L9.7345 9.20201L7.1614 8.32424C6.84724 8.21707 6.50087 8.30075 6.26671 8.54039L3.22595 11.6523C2.92468 11.9606 2.92468 12.4605 3.22595 12.7688C3.52721 13.0771 4.01565 13.0771 4.31691 12.7688L7.0655 9.95589L9.6386 10.8337C9.95276 10.9408 10.2991 10.8571 10.5333 10.6175L13.2369 7.85062L15.81 8.72839C16.1242 8.83556 16.4706 8.75188 16.7047 8.51224L19.4571 5.69543V6.94737C19.4571 7.38338 19.8025 7.73684 20.2286 7.73684C20.6546 7.73684 21 7.38338 21 6.94737V3.78947C21 3.68243 20.9792 3.58036 20.9415 3.48728ZM4.5 20V17H5.5V20H4.5ZM3 16.5C3 15.9477 3.44772 15.5 4 15.5H6C6.55228 15.5 7 15.9477 7 16.5V20.5C7 21.0523 6.55228 21.5 6 21.5H4C3.44772 21.5 3 21.0523 3 20.5V16.5ZM11.5 20H12.5V14H11.5V20ZM11 12.5C10.4477 12.5 10 12.9477 10 13.5V20.5C10 21.0523 10.4477 21.5 11 21.5H13C13.5523 21.5 14 21.0523 14 20.5V13.5C14 12.9477 13.5523 12.5 13 12.5H11ZM19.5 20H18.5V11H19.5V20ZM17 10.5C17 9.94771 17.4477 9.5 18 9.5H20C20.5523 9.5 21 9.94772 21 10.5V20.5C21 21.0523 20.5523 21.5 20 21.5H18C17.4477 21.5 17 21.0523 17 20.5V10.5Z"
        fill="white"
      />
    </svg>
  );
};
