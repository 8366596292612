// src/features/finchat/finchatSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FinchatMessagePayload,
  FinchatResponsePayload,
  Recommendation,
} from "../../assets/interfaces/interfaces";
type SessionId = string;
type FinChatMessage = {
  message_id: string;
  chart_data: Array<{
    chart_type: string;
    data: Array<unknown>;
    title: string;
    tooltipTitle: string;
  }>;
  chart_flag: boolean;
  list_of_recommendations: Recommendation[];
  input: string;
  output: string;
  outputLoading: boolean; // used for streaming output
  chat_id: string;
  list_of_tickers: string[];
};
interface FinchatState {
  finchatMessages: Record<SessionId, FinChatMessage>;
  loading: boolean;
}

const makeEmptyFinChatMessage = () => {
  return {
    chart_data: [],
    chart_flag: false,
    input: "",
    output: "",
    outputLoading: false,
    chat_id: "",
    message_id: "",
    list_of_tickers: [],
    list_of_recommendations: [],
  };
};

const initialState: FinchatState = {
  finchatMessages: {},
  loading: false,
};

export const finchatSlice = createSlice({
  name: "finchat",
  initialState,
  reducers: {
    clearFinchatData: () => initialState, // Resetting the state to its initial value
    finchatMessageLoad: (
      state,
      action: PayloadAction<FinchatMessagePayload>,
    ) => {
      state.loading = true;
      state.finchatMessages[action.payload.session_id] = state.finchatMessages[action.payload.session_id] || makeEmptyFinChatMessage();
      const finchatmessage = state.finchatMessages[action.payload.session_id];
      finchatmessage.outputLoading = true;
      finchatmessage.output = "";
      finchatmessage.input = action.payload.input;
      finchatmessage.chat_id = action.payload.chat_id;
      finchatmessage.chart_data = [];
      finchatmessage.list_of_tickers = [];
      finchatmessage.list_of_recommendations = [];
    },
    finchatMessageLoadSuccess: (state, action: PayloadAction<{ session_id: string; message: string; outputLoading: boolean }>) => {
      state.finchatMessages[action.payload.session_id] = state.finchatMessages[action.payload.session_id] || makeEmptyFinChatMessage();
      const finchatmessage = state.finchatMessages[action.payload.session_id];
      finchatmessage.output += action.payload.message;
      finchatmessage.outputLoading = action.payload.outputLoading;
      state.loading = true;
    },
    finchatMessageLoadFailure: (state) => {
      state.loading = false;
      // Handle failure (e.g., set an error state)
    },
    finchatMessageChartLoadSuccess: (
      state,
      action: PayloadAction<FinchatResponsePayload>,
    ) => {
      if (action.payload.chat_id === state.finchatMessages[action.payload.session_id].chat_id) {
        state.finchatMessages[action.payload.session_id].chart_data = action.payload.chart_data;
        state.finchatMessages[action.payload.session_id].chart_flag = action.payload.chart_flag;
        state.finchatMessages[action.payload.session_id].list_of_tickers = action.payload.list_of_tickers;
        state.finchatMessages[action.payload.session_id].list_of_recommendations = action.payload.list_of_recommendations;
      }
      state.loading = false; // Stop loading after receiving chart data
    },
    finchatMessageCancel: (state, action: PayloadAction<SessionId>) => {
      if (state.finchatMessages[action.payload]) {
        state.finchatMessages[action.payload].outputLoading = false;
      }
      state.loading = false;
    },
    finchatMessageCancelAll: (state) => {
      state.loading = false;
      for (const sessionId in state.finchatMessages) {
        if (Object.prototype.hasOwnProperty.call(state.finchatMessages, sessionId)) {
          state.finchatMessages[sessionId].outputLoading = false;
        }
      }
    },
  },
});

export const {
  clearFinchatData,
  finchatMessageLoad,
  finchatMessageLoadSuccess,
  finchatMessageChartLoadSuccess,
  finchatMessageLoadFailure,
  finchatMessageCancel,
  finchatMessageCancelAll,
} = finchatSlice.actions;

export default finchatSlice.reducer;
