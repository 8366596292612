import { Chip, IconButton } from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import { ReactComponent as WalletIcon } from "../../assets/icons/WalletIcon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/TradeIdeaModal/CrossIcon.svg";
import { ReactComponent as MinusStroked } from "../../assets/icons/TradeIdeaModal/MinusStroked.svg";
import { ReactComponent as PlusStroked } from "../../assets/icons/TradeIdeaModal/PlusStroked.svg";
import OptionDetails from "./OptionDetails";
import { setSelectedOptionByIndex } from "../../redux/slices/tradeIdeasSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { formatDate, getLegTypeText, numberFormatter } from "./helpers";
import { Order, TradeIdea } from "../../assets/interfaces/interfaces";
import { useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/icons/TradeIdeaModal/ChevronDown.svg";

type SharedContentProps = {
  onClose: () => void;
  optionContent?: { key: string; label: JSX.Element | string; value?: unknown }[];
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  isPreviewed: boolean;
  symbolQuote: { description?: string; last?: number };
  isExecuted?: boolean;
  selectedTrade?: TradeIdea;
  orderToClose?: Order;
};

enum SelectedTradeStatus {
  Traded = "traded",
}

enum SelectedOrderClasses {
  Multileg = "multileg",
}
export default function SharedContent({
  onClose,
  optionContent,
  quantity,
  setQuantity,
  isPreviewed,
  isExecuted,
  symbolQuote,
  orderToClose, // if not null, it is closing position modal
  selectedTrade,
}: SharedContentProps) {
  const dispatch = useDispatch<AppDispatch>();
  const virtualAccount = useSelector(
    (state: { tradierAccount }) => state.tradierAccount.account,
  );
  const { tradeIdeas } = useSelector(
    (state: { tradeIdeas }) => state.tradeIdeas,
  );

  if (!selectedTrade) {
    return <div>No trade idea selected.</div>;
  }

  const handleOptionChange = (event) => {
    const selectedId = Number(event.target.value);
    dispatch(setSelectedOptionByIndex(selectedId));
  };

  const DetailItem = ({ value, label, unit }) => {
    return (
      <div className="flex flex-col gap-y-1">
        <div className="flex items-baseline gap-x-1">
          <h5 className="tabular-nums">{value}</h5>
          {unit && <div className="body1 ">{unit}</div>}
        </div>
        <div className="body2">{label}</div>
      </div>
    );
  };

  const Counter = () => {
    const [localQuantity, setLocalQuantity] = useState(String(quantity)); // local state needed for debouncing input + prevent loss of focus
    const inputRef = useRef(null);

    const handleCounterChange = (e) => {
      const value = e.target.value;
      if (value === "" || /^\d+$/.test(value)) {
        setLocalQuantity(value);
      }
    };

    const handleBlur = () => {
      let finalQuantity = localQuantity === "" ? "1" : localQuantity;

      finalQuantity = String(Math.max(1, Number(finalQuantity)));

      if (!orderToClose) {
        setLocalQuantity(finalQuantity);
        setQuantity(Number(finalQuantity));
      }
    };

    return (
      <div className="flex items-center py-2 px-4 bg-white gap-x-4 rounded w-1/2 justify-center">
        <div className="body2">
          {(isPreviewed || isExecuted) ? `Quantity x ${quantity}` : "Quantity"}

        </div>

        {!isPreviewed && (
          <div className="flex rounded border border-mui-black-38 w-36 ps-2">
            <input
              type="text"
              value={localQuantity}
              onChange={handleCounterChange}
              onBlur={handleBlur}
              className="rounded focus:outline-none w-full text-end pe-2"
              ref={inputRef}
              disabled={orderToClose !== null}
            />
            <button
              className="border-l border-mui-black-38 p-1"
              disabled={orderToClose !== null}
              onClick={() => {
                const newQuantity = String(Math.max(1, Number(localQuantity) - 1));
                setLocalQuantity(newQuantity);
                setQuantity(Number(newQuantity));
              }}
            >
              <MinusStroked />
            </button>
            <button
              className="border-l border-mui-black-38 p-1"
              disabled={orderToClose !== null}
              onClick={() => {
                const newQuantity = String(Number(localQuantity) + 1);
                setLocalQuantity(newQuantity);
                setQuantity(Number(newQuantity));
              }}
            >
              <PlusStroked />
            </button>
          </div>
        )}
      </div>
    );
  };

  const generateUniqueLabels = (options) => {
    const labelCounts = new Map();
    const labelIndexTracker = new Map();

    options.forEach((option) => {
      const baseLabel = `${option.trade_idea.symbol} ${option.trade_idea.option_strategy}`;
      labelCounts.set(baseLabel, (labelCounts.get(baseLabel) || 0) + 1);
    });

    return options.map((option) => {
      const baseLabel = `${option.trade_idea.symbol} ${option.trade_idea.option_strategy}`;
      const count = labelCounts.get(baseLabel);

      const currentIndex = labelIndexTracker.get(baseLabel) || 1;
      labelIndexTracker.set(baseLabel, currentIndex + 1);

      // Generate the label, appending number only if there are duplicates
      const label = count > 1 ? `${baseLabel} #${currentIndex}` : baseLabel;

      return {
        ...option,
        label,
      };
    });
  };

  const uniqueOptions = generateUniqueLabels(tradeIdeas);
  console.log("trade ideas in shared", tradeIdeas);

  return (
    <>
      <section className="flex justify-between items-center gap-x-6">
        {!orderToClose && (
          <div className="w-1/2">
            <FormControl className="flex-1" sx={{ width: "100%" }} size="medium">
              <Select
                className="dropdown-icon"
                value={selectedTrade?.id || ""}
                onChange={handleOptionChange}
                renderValue={(selectedId) => {
                  const selectedTrade = uniqueOptions.find(
                    option => option.id === selectedId,
                  );
                  return selectedTrade?.label || "";
                }}
                IconComponent={ChevronDown}
              >
                {uniqueOptions.map(option => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {option.label}
                    {(option.status === SelectedTradeStatus.Traded) && (
                      <Chip
                        size="small"
                        color="warning"
                        label="Executed"
                        variant="filled"
                      />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {orderToClose && (
          <div className="body1 opacity-60">
            {selectedTrade.trade_idea?.symbol}
            {" "}
            {selectedTrade.trade_idea?.option_strategy}
            {" "}
            {orderToClose.class !== SelectedOrderClasses.Multileg && (
              <>
                $
                {Math.floor(selectedTrade.trade_idea.legs[0]?.strike_price)}
              </>
            )}
          </div>
        )}
        <div className={`flex items-center w-1/2 ${orderToClose ? "justify-end" : "justify-between"}`}>

          {!orderToClose && (
            <div className="flex items-center bg-white rounded-3xl p-4 gap-x-2">
              <WalletIcon />
              <div className="body2 tabular-nums">
                {numberFormatter(virtualAccount?.balance_cash)}
              </div>
            </div>
          )}
          {/* </div> */}
          <div className="flex gap-x-4 justify-end w-32">
            <IconButton size="small" onClick={onClose} className="fill-black">
              <CrossIcon className="text-black m-2" />
            </IconButton>
          </div>
        </div>
      </section>
      <section className="flex justify-between gap-x-6">
        <div className="flex gap-x-2 justify-between w-1/2">
          <DetailItem
            value={selectedTrade?.trade_idea.symbol}
            label={
              orderToClose ? orderToClose.company : symbolQuote?.description
            }
            unit="NASDAQ"
          />
          <DetailItem
            value={numberFormatter(symbolQuote?.last) || selectedTrade.trade_idea.current_price}
            label="Underlying Price"
            unit="USD"
          />
        </div>
        <Counter />
      </section>
      <OptionDetails
        optionContent={optionContent}
        isClosingPosition={!!orderToClose}
        isSingleLeg={
          orderToClose
            ? orderToClose.class !== SelectedOrderClasses.Multileg
            : (selectedTrade?.trade_idea?.legs?.length || 0) === 1
        }
      />
      <section>
        <div className="flex justify-center gap-x-6">
          {selectedTrade?.trade_idea.legs.map((leg, index) => (
            <>
              <div
                key={index}
                className="flex flex-1 border-b border-mui-black-12 text-center subtitle text-mui-gray-blue pb-2 gap-x-1 justify-center"
              >
                {getLegTypeText(leg)}
                <p className="tabular-nums">
                  $
                  {(leg.strike_price)}
                </p>
                •
                {" "}
                {formatDate(leg.expiry_date)}
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  );
}
