import { Sides } from "../../assets/interfaces/interfaces";
import moment from "moment-timezone";

const constructOptionSymbolsHelper = (tradeIdea) => {
  try {
    if (!tradeIdea || !Array.isArray(tradeIdea.legs) || tradeIdea.legs.length === 0) {
      throw new Error("Invalid tradeIdea or no legs available to construct option symbols");
    }

    const formatStrikePrice = (strikePrice) => {
      const centsSymbol = Math.floor(+strikePrice * 1000);
      const priceSymbol = `${centsSymbol}`.padStart(8, "0");
      return priceSymbol;
    };

    const symbolsArray = tradeIdea.legs.map((leg) => {
      const expirationDate = moment(leg.expiry_date).format("YYMMDD");
      const optionType = leg.put_call === "put" ? "P" : "C";
      const strikePrice = formatStrikePrice(leg.strike_price);

      return `${tradeIdea.symbol}${expirationDate}${optionType}${strikePrice}`;
    });

    return symbolsArray.join(",");
  }
  catch (error) {
    console.error("Error constructing option symbols:", error.message);
    return null;
  }
};

export const constructOptionSymbols = (tradeIdea) => {
  try {
    const symbols = constructOptionSymbolsHelper(tradeIdea);
    return symbols;
  }
  catch (error) {
    console.error("Error constructing option symbols:", error.message);
    return null;
  }
};

export const convertOptionSymbolToContract = (optionSymbol: string): { type: string; expiry: string } => {
  if (!optionSymbol) {
    return null;
  }
  const regexToExcludeSymbol = /(\d{6})([CP])(\d+)/;

  const match = optionSymbol.match(regexToExcludeSymbol);
  if (!match) {
    return null;
  }

  const expirationPart = match[1];
  const optionType = match[2];
  const strikePriceRaw = match[3];

  const expirationDate = moment(expirationPart, "YYMMDD").format("MMM D, YYYY");
  const strikePrice = parseInt(strikePriceRaw, 10) / 1000;
  const optionTypeStr = optionType === "C" ? "Call" : "Put";

  return { type: `${optionTypeStr} $${strikePrice}`, expiry: `${expirationDate}` };
};

export const capitalizeFirstLetter = str =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getLegTypeText = (leg) => {
  const { buy_sell, put_call } = leg;

  if (buy_sell === "sell" && put_call === "call") {
    return "Short Call";
  }
  else if (buy_sell === "sell" && put_call === "put") {
    return "Short Put";
  }
  else if (buy_sell === "buy" && put_call === "call") {
    return "Long Call";
  }
  else if (buy_sell === "buy" && put_call === "put") {
    return "Long Put";
  }

  return "unknown";
};

export const formatDate = (dateString) => {
  return moment(dateString).format("MMM DD, YYYY");
};

export const numberFormatter = (number, decimalPlaces = 2) => {
  if (isNaN(number)) return "";

  const formatter = customFormatter(decimalPlaces);
  let formattedNumber = formatter.format(number);

  if (decimalPlaces > 2) {
    formattedNumber = formattedNumber.replace(/(\.\d*?)0+$/, "$1");
    if (formattedNumber.endsWith(".")) {
      formattedNumber = formattedNumber.slice(0, -1);
    }
  }

  return formattedNumber;
};

const customFormatter = (decimalPlaces = 2) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const isTradeIdeaExpired = (createdDate: string): boolean => {
  const created = createdDate.endsWith("Z") ? new Date(createdDate) : new Date(createdDate + "Z");

  const now = new Date();

  const differenceInMs = now.getTime() - created.getTime();

  const differenceInMinutes = differenceInMs / (1000 * 60);

  return differenceInMinutes > 15;
};

// dashboard helpers
export function formatDateToGMT(dateString) {
  const date = new Date(dateString);

  // Format the date to "04 September 2024"
  const formattedDate = date.toLocaleDateString("en-GB", { day: "2-digit", month: "long", year: "numeric" });

  // Convert to GMT time zone
  const timeInGMT = date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", second: "2-digit", timeZone: "GMT" });

  return (
    <>
      {formattedDate}
      <br />
      {timeInGMT}
      {" "}
      GMT
    </>
  );
}

const timezoneAbbreviations = {
  "Asia/Singapore": "SGT",
  "America/New_York": "EST",
  "America/Los_Angeles": "PST",
};

export function formatDateToLocal(dateString) {
  const date = new Date(dateString);

  const formattedDate = moment(date).format("DD MMM YYYY");
  const userTimeZone = moment.tz.guess();
  const timeInLocal = moment(date).tz(userTimeZone).format("HH:mm:ss");

  const timeZoneAbbreviation = timezoneAbbreviations[userTimeZone] || moment.tz(date, userTimeZone).format("zz");

  return (
    <>
      {formattedDate}
      <br />
      {timeInLocal}
      {" "}
      {timeZoneAbbreviation}
    </>
  );
}

export const getClosingSide = (side) => {
  switch (side) {
    case "buy_to_open":
      return Sides.sell_to_close;
    case "sell_to_open":
      return Sides.buy_to_close;
    default:
      throw new Error(`Unknown trade side: ${side}`);
  }
};

// error handling for tradier
enum ErrorType {
  PriceHigherThanStop = "Invalid parameter, price[1]: must be higher than stop[2]",
  PriceLowerThanStop = "Invalid parameter, price[1]: must be lower than stop[2]",
  InsufficientFunds = "You do not have enough buying power for this trade",
  UndefinedSymbol = "Unable to preview undefined symbol:",
  NoOptionLevelPermission = "Your account does not have the option level permission for this trade",
  InadequateOcoPriceDiff = "OCO price difference should be at least 0.1$",
}
export const mapErrorMessage = (errorMessage: string) => {
  if (
    errorMessage.includes(ErrorType.PriceHigherThanStop)
    || errorMessage.includes(ErrorType.PriceLowerThanStop)
  ) {
    return "There may be a mismatch in the stop loss, take profit and/or limit price values";
  }
  else if (errorMessage.includes(ErrorType.InsufficientFunds)) {
    return errorMessage;
  }
  else if (errorMessage.includes(ErrorType.UndefinedSymbol)) {
    return "The selected option strategy may have expired, or is invalid.";
  }
  else if (errorMessage.includes(ErrorType.NoOptionLevelPermission)) {
    return "Your account does not have the options level permission for this trade as yet. Successfully opening any long position first will enable placing such orders.";
  }
  else if (errorMessage.includes(ErrorType.InadequateOcoPriceDiff)) {
    return "Stop loss and take profit legs should be at least $0.10 apart.";
  }
  else {
    return "Something went wrong, please try again later or let us know at support@tradealgo.com";
  }
};

export const isWithinMarketHours = () => {
  const isRestrictedMode = localStorage.getItem("isRestricted");
  if (isRestrictedMode === "true") {
    const currentTime = moment.tz("America/New_York");

    const marketOpen = currentTime.clone().set({ hour: 9, minute: 30, second: 0 });
    const marketClose = currentTime.clone().set({ hour: 16, minute: 0, second: 0 });

    return currentTime.isBetween(marketOpen, marketClose);
  }
  else {
    return true;
  }
};
