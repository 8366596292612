import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { ReactComponent as InfoToolTip } from "../../assets/icons/InfoTooltip.svg";

interface Column {
  id: string;
  label: string | JSX.Element;
  width?: string;
}

const CustomizedToolTip = styled(({ className, placement = "top", ...props }: TooltipProps) => {
  return (
    <Tooltip {...props} classes={{ popper: className }} placement={placement} />
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    paddingRight: "8px !important",
    paddingLeft: "8px !important",
    textAlign: "center",
    maxWidth: 252,
    fontSize: "12px",
    margin: "0px",
    boxSizing: "border-box",
  },
}));

export const OpenPositionsColumns: Column[] = [
  { id: "strategy", label: "Strategy" },
  { id: "contract", label: "Contract" },
  { id: "qty", label: "Qty" },
  { id: "midPrice", label: "Mid Price" },
  {
    id: "netCredit",
    label: (
      <div className="flex items-center gap-x-2">
        Net Cr/Dr
        <CustomizedToolTip title="Estimated total cost may contain [additional margin requirements as determined by the brokerage platform]." placement="top">
          <InfoToolTip className="cursor-pointer" />
        </CustomizedToolTip>
      </div>
    ),
  },
  { id: "profitloss", label: "P&L" },
  { id: "value", label: "Value" },
  { id: "stopprofit", label: "Stop Loss/Take Profit" },
  { id: "closePosition", label: "" },
];

export const OpenOrdersColumns: Column[] = [
  { id: "strategy", label: "Strategy", width: "15%" },
  { id: "contract", label: "Contract", width: "13%" },
  { id: "qty", label: "Qty (Filled/Total)", width: "13%" },
  { id: "status", label: "Status", width: "13%" },
  { id: "unitPrice", label: "Unit Price", width: "9%" },
  { id: "totalCost", label: "Total Cost (Est.)", width: "12%" },
  { id: "orderType", label: "Order Type", width: "10%" },
  { id: "cancel", label: "", width: "13%" },
];

export const OrderHistoryColumns: Column[] = [
  { id: "strategy", label: "Strategy", width: "15%" },
  { id: "contract", label: "Contract", width: "11%" },
  { id: "avgPrice",
    label: (
      <div className="flex items-center gap-x-2">
        Average Price
        <CustomizedToolTip title="Estimated total cost may contain [additional margin requirements as determined by the brokerage platform]." placement="top">
          <InfoToolTip className="cursor-pointer" />
        </CustomizedToolTip>
      </div>
    ),
    width: "14%" },
  { id: "netCredit", label: "Net Cr/Dr", width: "13%" },
  { id: "orderVal",
    label: (
      <div className="flex items-center gap-x-2">
        Order Value
        <CustomizedToolTip title="Estimated total cost may contain [additional margin requirements as determined by the brokerage platform]." placement="top">
          <InfoToolTip className="cursor-pointer" />
        </CustomizedToolTip>
      </div>
    ), width: "12%" },
  { id: "qty", label: "Qty", width: "8%" },
  { id: "status", label: "Status", width: "14%" },
  { id: "lastUpdated", label: "Last Updated", width: "11%" },
];
