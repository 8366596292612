export const OrderTypeSwitcher = ({ isMarket, setIsMarket }) => {
  // const [isLimit, setIsLimit] = useState(!isMarket);

  // const handleClick = () => {
  //   setIsLimit(!isLimit);
  // };

  return (
    <div className="flex items-center cursor-pointer" onClick={() => setIsMarket(prev => !prev)}>
      <svg
        className={`duration-500 ease-in-out me-2 ${isMarket ? "rotate-180" : ""} fill-mui-black-56`}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0196 2.31344C10.8244 2.11818 10.5078 2.11818 10.3125 2.31344C10.1173 2.5087 10.1173 2.82528 10.3125 3.02055L12.1257 4.83367L2.66609 4.83366C2.38995 4.83366 2.16609 5.05752 2.16609 5.33366C2.16609 5.60981 2.38995 5.83366 2.66609 5.83366L12.1257 5.83367L10.3126 7.64678C10.1174 7.84204 10.1174 8.15862 10.3126 8.35388C10.5079 8.54915 10.8245 8.54915 11.0197 8.35388L13.6864 5.68722C13.8816 5.49196 13.8816 5.17537 13.6864 4.98011L13.6813 4.97512L11.0196 2.31344ZM4.9792 7.64677C5.17447 7.45151 5.49105 7.45151 5.68631 7.64677C5.88157 7.84203 5.88157 8.15862 5.68631 8.35388L3.87319 10.167L13.3328 10.167C13.6089 10.167 13.8328 10.3909 13.8328 10.667C13.8328 10.9431 13.6089 11.167 13.3328 11.167L3.87312 11.167L5.68624 12.9801C5.8815 13.1754 5.8815 13.492 5.68624 13.6872C5.49097 13.8825 5.17439 13.8825 4.97913 13.6872L2.31246 11.0206C2.1172 10.8253 2.1172 10.5087 2.31246 10.3134L2.31752 10.3085L4.9792 7.64677Z"
        />
      </svg>
      {isMarket ? "Market" : "Limit"}
    </div>
  );
};
