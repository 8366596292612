import { Button, IconButton, Modal } from "@mui/material";
import * as React from "react";
import { CrossIcon } from "../Plans/icons/GPTIcons/CrossIcon";
import { ReactComponent as TimerRounded } from "../../assets/icons/TradeIdeaModal/TimerRounded.svg";

export interface INotInMarketHoursModalProps {
  open: boolean;
  onClose: () => void;
  isClosingPosition?: boolean;
}

export default function NotInMarketHoursModal({ open, onClose, isClosingPosition = false }: INotInMarketHoursModalProps) {
  return (
    <Modal open={open} onClose={onClose} className="custom-overlay">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col gap-y-6 shadow w-modal-mobile sm:w-modal-sm md:w-modal-md lg:w-modal-lg rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
          <div className="flex justify-between items-center">
            <h6 className="font-medium">{isClosingPosition ? "Order cannot be submitted right now." : "Trade ideas cannot be executed right now."}</h6>
            <IconButton
              size="small"
              onClick={onClose}
              className="cross-icon"
            >
              <CrossIcon className="text-current m-2" />
            </IconButton>
          </div>
          <p className="body2">It is currently outside of regular trading hours — please try again when the market opens.</p>
          <div className="bg-mui-light-gray dark:bg-mui-gray p-2 flex items-center justify-center gap-x-2">
            <TimerRounded />
            <p className="body2">
              Regular trading hours are
              {" "}
              <strong>9:30 AM to 4:00 PM EST.</strong>
            </p>
          </div>
          <div className="self-center w-[232px]">
            <Button
              variant="contained"
              color="inherit"
              size="large"
              className="button-submit"
              fullWidth
              onClick={onClose}
            >
              Got it
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
