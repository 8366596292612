import usePolling from "../../hooks/usePolling";

export const fetchMarketDataForOption = (tradierId: string, optionSymbols: string[] | string) => {
  const baseUrl = `${process.env.REACT_APP_SPL_URL}/tradier/accounts/${tradierId}/market-quotes`;
  const query = Array.isArray(optionSymbols)
    ? optionSymbols.map(symbol => `symbol=${encodeURIComponent(symbol)}`).join("&")
    : `symbol=${encodeURIComponent(optionSymbols)}`;

  const url = `${baseUrl}?${query}`;
  const { data } = usePolling(url, 6000);
  return data;
};
