import { Button, Checkbox, FormControlLabel, Modal } from "@mui/material";
import { ReactComponent as ErrorRounded } from "../../assets/icons/TradeIdeaModal/ErrorRounded.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/TradeIdeaModal/LeftArrow.svg";

type CancelSLTPModalProps = {
  open: boolean;
  closeParentModal: () => void;
  closeWarning: () => void;
  handlePreview: () => void;
};
export default function CancelSLTPModal({ open, closeParentModal, closeWarning, handlePreview }: CancelSLTPModalProps) {
  const handleSavePreferences = (event) => {
    const isChecked = event.target.checked;
    localStorage.setItem("hide-sltp-warning", JSON.stringify(isChecked));
  };

  return (
    <Modal open={open}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 shadow w-modal-lg text-mui-black-87 bg-white rounded h-[550px] flex flex-col justify-center items-center gap-y-4">
        <div className="flex flex-col items-center gap-y-6 text-center">
          <ErrorRounded />
          <p className="body1 px-20">This order’s Stop Loss & Take Profit will need to be cancelled before the close order can be submitted.</p>
          <div className="flex gap-x-6">
            <Button
              variant="outlined"
              size="large"
              color="inherit"
              className="tradesButton-outlined"
              onClick={closeParentModal}
            >
              <LeftArrow className="me-2" />
              Back to Open Positions
            </Button>
            <Button
              variant="contained"
              size="large"
              className="tradesButton-submit button-loading"
              onClick={() => {
                handlePreview();
                closeWarning();
              }}
            >
              Proceed to close position
            </Button>
          </div>
        </div>
        <FormControlLabel
          className="checkbox-label"
          control={(
            <Checkbox
              onChange={handleSavePreferences}
            />
          )}
          label="Don’t show me this again today."
        />
      </div>

    </Modal>
  );
}
