import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import { ReactComponent as CrossIcon } from "../../assets/icons/TradeIdeaModal/CrossIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { cancelOrder } from "../../redux/slices/tradeExecutionSlice";
import { AppDispatch } from "../../redux/store";
import { getUserTrades } from "../../redux/slices/tradeOrdersSlice";

export default function CancelOrderModal({ open, onClose, isSecondary, order }) {
  const virtualAccount = useSelector(
    (state: { tradierAccount }) => state.tradierAccount.account,
  );
  const {
    isCancelLoading,
    error: cancelError,
  } = useSelector((state: { tradeExecution }) => state.tradeExecution);

  const dispatch = useDispatch<AppDispatch>();
  const handleCancelOrder = async () => {
    try {
      const cancelOrderResult = await dispatch(
        cancelOrder({
          accountId: virtualAccount.external_id,
          orderId: order.external_order_id,
          vendor: "tradier",
        }),
      );

      if (cancelOrder.fulfilled.match(cancelOrderResult) && !cancelError) {
        onClose();
        await dispatch(getUserTrades({ vendor: "tradier" }));
      }
      else {
        throw new Error("Error during cancelOrder dispatch");
      }
    }
    catch (error) {
      console.error("Error during cancelOrder dispatch: ", error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="custom-overlay">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="shadow w-modal-mobile sm:w-modal-sm md:w-modal-md lg:w-modal-lg rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-16 dark:text-white">
            <div className="flex flex-col gap-y-6">
              <div className="flex justify-between">
                {!isSecondary && <h6>Would you like to proceed with cancelling this order?</h6>}
                {isSecondary && <h6>Are you sure about removing [Stop Loss] from this order?</h6>}
                <IconButton
                  size="small"
                  onClick={onClose}
                  className="cross-icon"
                >
                  <CrossIcon className="text-current m-2" />
                </IconButton>
              </div>
              {!isSecondary && <p className="body2">Your order has not been filled, cancelling will not affect you at all</p>}
              {isSecondary && <p className="body2">Removing [Stop Loss] might .....</p>}
              <div className="flex gap-x-6">
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  fullWidth
                  className="button-danger"
                  onClick={handleCancelOrder}
                >
                  {isCancelLoading
                    ? (
                        <div className="flex items-center">
                          <CircularProgress color="inherit" size={25} />
                        </div>
                      )
                    : (
                        "Yes, cancel"
                      )}
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  fullWidth
                  className="button-outlined"
                  disabled={isCancelLoading}
                  onClick={onClose}
                >
                  No, I will not cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
