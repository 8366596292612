import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface MappingForPreviewArgs {
  provider: string;
  selectedOption: object;
}
export interface PreviewTradeArgs {
  accountId: string;
  order: object;
}
export interface ExecuteTradeArgs {
  tradeIdeaId: number;
  accountId: string;
  order: object;
  vendor: string;
}
export interface CancelOrderArgs {
  accountId: string;
  orderId: string;
  vendor: string;
}
const initialState = {
  error: null,
  mappedTrade: {},
  isPreviewLoading: false,
  isExecuteLoading: false,
  isCancelLoading: false,
};

export const mapTradeForPreview = createAsyncThunk(
  "tradeIdea/mappingForPreview",
  async (arg: MappingForPreviewArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/map-orders/${arg.provider}`,
        arg.selectedOption,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const previewOrder = createAsyncThunk(
  "tradeIdea/previewTrade",
  async (arg: PreviewTradeArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/tradier/orders/${arg.accountId}/preview`,
        arg.order,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.log("error: ", error.response);
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);
export const executeOrder = createAsyncThunk(
  "tradeIdea/executeOrder",
  async (arg: ExecuteTradeArgs, { rejectWithValue }) => {
    try {
      const query = {
        order: arg.order,
        accountId: arg.accountId,
        tradeIdeaId: arg.tradeIdeaId,
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-execute-order/${arg.vendor}`,
        query,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.log("error in trade exec slice: ", error.response);
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);
export const cancelOrder = createAsyncThunk(
  "tradeIdea/cancelOrder",
  async (arg: CancelOrderArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/${arg.vendor}/orders/${arg.accountId}/cancel/${arg.orderId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      console.log("cancel order", data);
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.log("error: ", error.response);
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const tradeExecutionSlice = createSlice({
  name: "tradeExecution",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(mapTradeForPreview.pending, (state) => {
        state.isPreviewLoading = true;
      })
      .addCase(mapTradeForPreview.fulfilled, (state, action) => {
        state.isPreviewLoading = true;
        state.mappedTrade = action.payload;
        state.error = null;
      })
      .addCase(previewOrder.pending, (state) => {
        state.isPreviewLoading = true;
        state.error = null;
      })
      .addCase(previewOrder.fulfilled, (state) => {
        state.isPreviewLoading = false;
        state.error = null;
      })
      .addCase(previewOrder.rejected, (state, action) => {
        state.isPreviewLoading = false;
        state.error = action.payload || "An error occurred while previewing the order.";
      })
      .addCase(executeOrder.pending, (state) => {
        state.isExecuteLoading = true;
        state.error = null;
      })
      .addCase(executeOrder.fulfilled, (state) => {
        state.isExecuteLoading = false;
        state.error = null;
      })
      .addCase(executeOrder.rejected, (state, action) => {
        state.isExecuteLoading = false;
        console.log("hit exec order reject, error state", action.payload);
        state.error = action.payload || "An error occurred while executing the order.";
      })
      .addCase(cancelOrder.pending, (state) => {
        state.isCancelLoading = true;
        state.error = null;
      })
      .addCase(cancelOrder.fulfilled, (state) => {
        state.isCancelLoading = false;
        state.error = null;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.isCancelLoading = false;
        state.error = action.payload || "An error occurred while cancelling the order.";
      });
  },
});

export default tradeExecutionSlice.reducer;
