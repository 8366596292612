import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { TradierUserPayload, TradierVirtualAccountPayload } from "../../assets/interfaces/interfaces";

// try subscribing to store
const initialState = {
  status: "",
  user: {},
  tradierAccountExists: false,
  account: {},
  error: null,
};

export const postTradierUser = createAsyncThunk(
  "tradierUser/postTradierUser",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/tradier/user`,
        {},
        {
          withCredentials: true,
        },
      );
      return {
        user: data.user,
      };
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

// tradier virtual accounts
export const getTradierAccount = createAsyncThunk(
  "tradierAccount/getTradierAccount",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/tradier/accounts`,
        { withCredentials: true },
      );
      return {
        account: data.accounts.length > 0 ? data.accounts[0] : null,
      };
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const createVirtualAccount = createAsyncThunk(
  "tradierAccount/createVirtualAccount",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SPL_URL}/tradier/accounts/virtual`,
        {},
        {
          withCredentials: true,
        },
      );
      console.log("Attempted to create virtual account", data);
      return {
        account: data.account,
      };
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const tradierAccountSlice = createSlice({
  name: "tradierAccount",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(postTradierUser.fulfilled, (state, action: PayloadAction<TradierUserPayload>) => {
        state.status = "succeeded";
        state.user = action.payload.user;
      })
      .addCase(postTradierUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch tradier user";
      })
      .addCase(getTradierAccount.fulfilled, (state, action: PayloadAction<TradierVirtualAccountPayload>) => {
        state.status = "succeeded";
        state.account = action.payload.account;
        if (!action.payload.account) {
          state.error = "No account found";
        }
      })
      .addCase(getTradierAccount.rejected, (state, action) => {
        state.error = action.payload || "Failed to fetch account";
      })
      .addCase(createVirtualAccount.fulfilled, (state, action: PayloadAction<TradierVirtualAccountPayload>) => {
        state.status = "succeeded";
        state.account = action.payload.account;
        if (!action.payload.account) {
          state.error = "No account found";
        }
      })
      .addCase(createVirtualAccount.rejected, (state, action) => {
        state.error = action.payload || "Error in creating virtual account";
      });
  },
});

export default tradierAccountSlice.reducer;
