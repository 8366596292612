export const MessageIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6985 17.1735L11.6416 17.1691H11.5829C11.0627 17.1691 10.6454 17.3659 10.3324 17.5825L10.3239 17.5885C9.82466 17.9392 9.14659 18.3192 8.44045 18.6523L8.4476 18.6408L8.47018 18.6045L8.49064 18.567C8.69531 18.1916 8.78733 17.6991 8.62237 17.1954C8.46091 16.7024 8.11124 16.3742 7.76471 16.1925C5.29277 14.8959 4 12.9147 4 10.8474C4 7.64994 7.41617 4.5 12.5001 4.5C17.5839 4.5 21 7.64993 21 10.8474C21 13.9765 17.8456 17.1864 11.9268 17.1864C11.8792 17.1864 11.8222 17.1828 11.7002 17.1737L11.6985 17.1735ZM11.9268 18.6864C11.8133 18.6864 11.6998 18.6779 11.5862 18.6693L11.5829 18.6691C11.4594 18.6691 11.336 18.7122 11.1861 18.8159C9.95037 19.684 7.94175 20.605 6.68135 20.9018L6.67475 20.9034C6.65391 20.9082 6.63327 20.913 6.61284 20.9175L6.59021 20.9225C6.35945 20.9726 6.15667 21 5.99207 21C5.31305 21 5.11908 20.4561 5.46295 20.0244C5.56056 19.8987 5.70473 19.7289 5.87196 19.5319L5.87206 19.5318L5.87756 19.5253C6.28394 19.0468 6.82538 18.4092 7.17371 17.8489C7.24426 17.7195 7.2002 17.59 7.06793 17.5209C4.23718 16.036 2.5 13.61 2.5 10.8474C2.5 6.505 6.94446 3 12.5001 3C18.0556 3 22.5 6.505 22.5 10.8474C22.5 15.1467 18.2849 18.6864 11.9268 18.6864ZM8.5 11.85C9.05228 11.85 9.5 11.4023 9.5 10.85C9.5 10.2977 9.05228 9.84998 8.5 9.84998C7.94772 9.84998 7.5 10.2977 7.5 10.85C7.5 11.4023 7.94772 11.85 8.5 11.85ZM16.5 11.85C17.0523 11.85 17.5 11.4023 17.5 10.85C17.5 10.2977 17.0523 9.84998 16.5 9.84998C15.9477 9.84998 15.5 10.2977 15.5 10.85C15.5 11.4023 15.9477 11.85 16.5 11.85ZM13.5 10.85C13.5 11.4023 13.0523 11.85 12.5 11.85C11.9477 11.85 11.5 11.4023 11.5 10.85C11.5 10.2977 11.9477 9.84998 12.5 9.84998C13.0523 9.84998 13.5 10.2977 13.5 10.85Z"
        fill="currentColor"
        fillOpacity="0.56"
      />
    </svg>
  );
};
