import { Divider } from "@mui/material";
import React from "react";

export default function OptionDetails({ optionContent, isSingleLeg = false, footerLabels = [], isClosingPosition = false }) {
  const labelsToInclude = () => {
    if (isClosingPosition) {
      if (isSingleLeg) {
        return ["orderType", "action", "timeInForce", "contractType", "profitLoss", "orderCostForSingle", "platformFees", "estimatedTotalProceeds", "proceedsOfClosing"];
      }
      else {
        return ["orderType", "timeInForce", "platformFees", "orderCostForMulti", "emptyRow", "estimatedTotalProceeds", "nettCreditOrDebit"];
      }
    }

    if (footerLabels.length > 0) {
      return footerLabels;
    }

    return isSingleLeg
      ? ["breakevenPrice", "platformFees", "emptyRow", "orderCostForSingle", "emptyRow", "estimatedTotalProceeds"]
      : ["orderType", "platformFees", "timeInForce", "orderCostForMulti", "strategy", "estimatedTotalProceeds", "breakevenPrice", "emptyRow", "nettCreditOrDebit", "probabilityOfProfit", "maxLoss", "stopLoss", "maxProfit", "takeProfit"];
  };

  const includedLabels = labelsToInclude();

  const filteredContent = optionContent.filter(item => includedLabels.includes(item.key));
  const sortedContent = filteredContent.sort((a, b) => {
    return includedLabels.indexOf(a.key) - includedLabels.indexOf(b.key);
  });
  return (
    <div className="grid grid-cols-2 gap-y-2 gap-x-6 body2 tabular-nums">
      {sortedContent.map(({ label, value }, index) => {
        const isLastItem = index === filteredContent.length - 1;
        const gapForSingleModal = index === 5 && isSingleLeg && footerLabels.length > 0;
        const dividerForTradeIdeaModal = index === 7 && !isClosingPosition;
        const dividerForClosePositionModal = isClosingPosition && (
          (isSingleLeg && index === 7)
          || (!isSingleLeg && index === 5)
        );

        return (
          <React.Fragment key={index}>
            <div className={`flex justify-between ${gapForSingleModal && "pb-2"} ${isLastItem && !isClosingPosition && "col-start-2"} ${isLastItem && "col-start-1"}`}>
              <div>{label}</div>
              <div>{value}</div>
            </div>
            {dividerForTradeIdeaModal && (
              !isSingleLeg && (
                <div className="col-span-2 py-2">
                  <Divider className="opacity-12" />
                </div>
              )
            )}

            {dividerForClosePositionModal && (
              <div className="col-span-2 flex items-center">
                <Divider className="flex-grow opacity-12" />
                <span className="mx-2 overline-text">PER QUANTITY, UP TO XX</span>
                <Divider className="flex-grow opacity-12" />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
