import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { OpenPositionsColumns, OpenOrdersColumns, OrderHistoryColumns } from "./DashboardInfo";
import OpenOrders from "./OpenOrders";
import OpenPositions, { getPrimaryOrderForLimitOrders } from "./OpenPositions";
import OrderHistory from "./OrderHistory";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getUserTrades } from "../../redux/slices/tradeOrdersSlice";
import { AppDispatch } from "../../redux/store";

export const isMultiLegOrder = order =>
  order.type === "multileg" || order.class === "multileg";

export const isLimitOrder = order =>
  order.type === "otoco" || order.type === "oto" || (order.type === "limit" && order.raw_order); // use of raw order to differentiate between limit orders (no sltp) vs SLTP
export const isSecondaryOrder = order => (order.type === "limit" && !order.raw_order) || order.type === "stop";
const tableType = {
  openPositions: 0,
  openOrders: 1,
  orderHistory: 2,
};
const columnMapping = {
  [tableType.openPositions]: OpenPositionsColumns,
  [tableType.openOrders]: OpenOrdersColumns,
  [tableType.orderHistory]: OrderHistoryColumns,
};
function OrdersTable({ value, navigateTab, openOrdersSymbols }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [value]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { orders, trades, isLoading } = useSelector((state: { tradeOrders }) => state.tradeOrders);

  useEffect(() => {
    dispatch(getUserTrades({ vendor: "tradier" }));
  }, [dispatch]);

  const findTradeByOrderId = (orderId) => {
    const trade = trades.find(trade => trade.order_id === orderId);
    return trade.trade_idea[0];
  };

  const isIncompleteMultileg = order =>
    (order.type === "market" && order.status === "pending")
    || (isMultiLegOrder(order) && order.status === "pending");

  // orders that are pending closure should not show up in open positions
  const isPendingCloseOrder = order =>
    findTradeByOrderId(order.external_order_id)?.trade_idea?.position === "close" && order.status === "pending";

  // otoco/oco orders that are still pending cancellation should still show up in filledorders
  const isCanceledOrderWithAllLegsCanceled = order =>
    order.status === "canceled" && (!order.legs || order.legs.every(leg => leg.status === "canceled"));

  // filter out otoco/oco orders that are expired but status = 'open'
  const isOpenOrderWithExpiredPrimaryLeg = order =>
    order.status === "open" && getPrimaryOrderForLimitOrders(order.legs)?.status === "expired";

  const isOptionInOpenOrders = order =>
    order.option_symbol && openOrdersSymbols.includes(order.option_symbol);

  const areLegsInOpenOrders = order =>
    order.option_symbol === null && Array.isArray(order.legs)
    && order.legs.every(leg => openOrdersSymbols.includes(leg.option_symbol));

  const shouldExcludeOrder = order =>
    isIncompleteMultileg(order)
    || isPendingCloseOrder(order)
    || isCanceledOrderWithAllLegsCanceled(order)
    || isOpenOrderWithExpiredPrimaryLeg(order);

  const filledOrders = orders.filter((order) => {
    if (shouldExcludeOrder(order)) {
      return false;
    }

    // Check if option symbol or legs match open orders
    return isOptionInOpenOrders(order) || areLegsInOpenOrders(order);
  });

  const orderGroups = {};

  filledOrders.forEach((order) => {
    const tradeId = findTradeByOrderId(order.external_order_id)?.id;
    if (tradeId) {
      if (!orderGroups[tradeId]) {
        orderGroups[tradeId] = [];
      }
      orderGroups[tradeId].push(order);
    }
  });

  const filteredFilledOrders = filledOrders.filter((order) => {
    const tradeId = findTradeByOrderId(order.external_order_id)?.id;
    const storedOrder = orderGroups[tradeId];
    // Only filter out if there are two or more "filled" close orders with the same id
    const hasMultipleCloseFilled = storedOrder
      && storedOrder.filter(o => findTradeByOrderId(o.external_order_id)?.trade_idea?.position === "close" && (o.status === "filled" || o.status === "canceled")).length >= 2;

    return !hasMultipleCloseFilled;
  });

  const filledOrderIds = new Set(filteredFilledOrders.map(order => order.id));

  const pendingOrders = orders.filter((order) => {
    if (filledOrderIds.has(order.id) || order.status === "filled" || order.status === "expired" || order.status === "canceled") {
      return false;
    }

    if (isLimitOrder(order) && order.legs) {
      const primaryOrder = getPrimaryOrderForLimitOrders(order.legs);
      if (primaryOrder && primaryOrder.status === "expired") {
        return false;
      }
    }
    return true;
  });

  const historyOrders = [];
  orders.filter((order) => {
    // add whole order if primary order is filled + add secondary leg as an order if it is filled
    if (isLimitOrder(order) && order.legs) {
      const primaryOrder = getPrimaryOrderForLimitOrders(order.legs);

      if (primaryOrder && (primaryOrder.status === "filled" || primaryOrder.status === "expired" || order.status === "canceled")) {
        historyOrders.push(order);
      }
      order.legs
        .filter(leg => leg !== primaryOrder)
        .forEach((leg) => {
          if (leg.status === "filled") {
            historyOrders.push(leg);
          }
        });
    }
    else {
      if (order.status === "filled" || order.status === "canceled" || order.status === "expired") {
        historyOrders.push(order);
      }
    }
  });

  const ordersDataMap = {
    [tableType.openPositions]: filteredFilledOrders,
    [tableType.openOrders]: pendingOrders,
    [tableType.orderHistory]: historyOrders,
  };

  const ordersData = ordersDataMap[value] || [];
  const sortedOrdersData = ordersData.sort((a, b) => {
    const transactionDateA = isLimitOrder(a) ? getPrimaryOrderForLimitOrders(a.legs)?.transaction_date : a.transaction_date;
    const transactionDateB = isLimitOrder(b) ? getPrimaryOrderForLimitOrders(b.legs)?.transaction_date : b.transaction_date;

    const dateA = new Date(transactionDateA);
    const dateB = new Date(transactionDateB);

    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
      return 0;
    }

    return dateB.getTime() - dateA.getTime();
  });

  const paginatedContent = sortedOrdersData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  let isDataLoading = (isLoading || !(paginatedContent.length > 0));

  if (!isLoading && ordersData.length === 0) {
    isDataLoading = false;
  }
  const bodyMapper = {
    [tableType.openPositions]: (
      <OpenPositions
        filledOrders={paginatedContent}
        findTradeByOrderId={findTradeByOrderId}
      />
    ),
    [tableType.openOrders]: (
      <OpenOrders
        pendingOrders={paginatedContent}
        findTradeByOrderId={findTradeByOrderId}
        navigateTab={navigateTab}
      />
    ),
    [tableType.orderHistory]: (
      <OrderHistory
        historyOrders={paginatedContent}
        findTradeByOrderId={findTradeByOrderId}
      />
    ),
  };
  const columns = columnMapping[value] || [];
  const bodyContent = bodyMapper[value] || null;
  return (
    <div className="flex-grow flex flex-col overflow-hidden">
      <TableContainer className="flex-grow overflow-y-auto">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  sx={column.width ? { width: column.width } : {}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isDataLoading
            ? (
                <TableBody>
                  {Array(5).fill(
                    <TableRow className="loading-table-rows">
                      <TableCell colSpan={columns.length}>
                        <Skeleton variant="text" sx={{ fontSize: "5rem", width: "100%" }} animation="wave" />
                      </TableCell>
                    </TableRow>,
                  )}
                </TableBody>
              )
            : (
                <TableBody className="tabular-nums">
                  {bodyContent}
                </TableBody>
              )}
        </Table>
      </TableContainer>
      <TablePagination
        className="dashboard-pagination"
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: 1 }]}
        colSpan={3}
        count={sortedOrdersData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        slotProps={{
          select: {
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          },
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default OrdersTable;
