import { Chip } from "@mui/material";
import { ReactComponent as CircledCheckIcon } from "../../assets/icons/PerformanceDashboard/CircledCheckedIcon.svg";
import { capitalizeFirstLetter } from "../OptionsTrading/helpers";
import { ReactComponent as CircledCross } from "../../assets/icons/PerformanceDashboard/CircledCross.svg";

type StatusChipProps = {
  status: string | number;
  handleStatusClick?: () => void;
};

export const StatusChip = ({ status }: StatusChipProps) => {
  if (status === OrderStatus.pending || status === OrderStatus.open || status === OrderStatus.held) {
    return <Chip label={capitalizeFirstLetter(status)} />;
  }
  else if (status === OrderStatus.expired) {
    return (
      <Chip
        label="Expired"
        color="error"
      />
    );
  }
  else if (status === OrderStatus.canceled) {
    return (
      <Chip
        label="Cancelled"
        color="warning"
        deleteIcon={<CircledCross className="opacity-70" />}
        className="no-click-icon"
        onDelete={() => {}}
      />
    );
  }
  else if (status === "filled") {
    return (
      <Chip
        label="Filled"
        color="success"
        deleteIcon={<CircledCheckIcon />}
        className="no-click-icon"
        onDelete={() => {}}
      />
    );
  }
  else {
    return <Chip label={status} />;
  }
};

export enum OrderStatus {
  pending = "pending",
  open = "open",
  held = "held",
  filled = "filled",
  expired = "expired",
  canceled = "canceled",
}
