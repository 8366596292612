import { Box, Collapse, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { formatDateToLocal, numberFormatter } from "../OptionsTrading/helpers";
// import { StatusChip } from "./StatusChip";
import { isLimitOrder, isMultiLegOrder, isSecondaryOrder } from "./OrdersTable";
import { getPrimaryOrderForLimitOrders } from "./OpenPositions";
import { useState } from "react";
import { renderContractType, renderQuantity, renderStatus, renderStrategyCell } from "./OpenOrders";
import { TradeStrategies } from "../../assets/interfaces/interfaces";

const OrderHistory = ({ findTradeByOrderId, historyOrders }) => {
  const [openRowId, setOpenRowId] = useState(null);

  const handleExpandClick = (orderId) => {
    setOpenRowId(prevId => prevId === orderId ? null : orderId);
  };

  const renderNetCreditDebit = (isMultiLeg, legs, isStraddle = false, isCancelled = false) => {
    if (!isMultiLeg || isStraddle || isCancelled) {
      return <>—</>;
    }

    let netAmount = 0;
    legs.forEach((leg) => {
      const transactionValue = parseFloat(leg.transaction_value);

      if (leg.side.includes("buy")) {
        netAmount -= transactionValue;
      }
      else if (leg.side.includes("sell")) {
        netAmount += transactionValue;
      }
    });

    const netCreditDebit = netAmount < 0
      ? {
          amount: `-$${Math.abs(netAmount).toFixed(2)} DR`,
          className: "text-mui-primary-red-light",
        }
      : {
          amount: `+$${Math.abs(netAmount).toFixed(2)} CR`,
          className: "text-mui-green-text-success",
        };

    return (
      <div className={netCreditDebit.className}>
        {netCreditDebit.amount}
      </div>
    );
  };

  const calculateOrderValue = (order, associatedTrade, primaryOrder) => {
    if (parseFloat(order?.avg_fill_price) > 0 || parseFloat(primaryOrder?.avg_fill_price) > 0) {
      const marginChange = associatedTrade?.preview_result?.orders?.[0]?.margin_change || 0;

      let avgFillPriceValue = 0;
      if (primaryOrder) {
        avgFillPriceValue = primaryOrder.avg_fill_price * 100 * primaryOrder.total_quantity;
      }
      else {
        avgFillPriceValue = order.avg_fill_price * 100 * order.total_quantity;
      }

      const commission = associatedTrade?.preview_result?.orders?.[0]?.commission || 0;

      if (marginChange > 0) {
        return numberFormatter(Math.abs(-marginChange + avgFillPriceValue + commission));
      }
      else {
        return numberFormatter(Math.abs(marginChange + avgFillPriceValue + commission));
      }
    }

    return null; // Return null if no valid avg_fill_price
  };

  console.log("history order", historyOrders);
  return (
    <>
      {historyOrders.map((order) => {
        const isLimit = isLimitOrder(order);
        const primaryOrder = isLimit ? getPrimaryOrderForLimitOrders(order.legs) : null; // only otoco and oco have primary order
        const associatedTrade = isSecondaryOrder(order) ? findTradeByOrderId(order.parent_id) : findTradeByOrderId(order.external_order_id);
        const isMultiLeg = isMultiLegOrder(order);
        const isCancelled = order.status === "canceled";
        const isStraddle = associatedTrade.trade_idea?.option_strategy === TradeStrategies.Straddle;

        return (
          <>
            <TableRow key={order.id} className="body2">
              <TableCell id="strategy">
                {renderStrategyCell(order, isMultiLeg, associatedTrade, isLimit, handleExpandClick, openRowId)}
              </TableCell>
              <TableCell id="contractType">
                {renderContractType(isMultiLeg, order, primaryOrder, isSecondaryOrder(order))}
              </TableCell>
              <TableCell id="avgPrice">
                {parseFloat(order?.avg_fill_price) > 0
                && numberFormatter(order?.avg_fill_price)}
                {primaryOrder && numberFormatter(primaryOrder.avg_fill_price)}
              </TableCell>
              <TableCell id="netCredit">
                {renderNetCreditDebit(isMultiLeg, order.legs, isStraddle, isCancelled)}
              </TableCell>
              <TableCell id="orderValue">
                {/* {parseFloat(order.avg_fill_price) > 0
                && numberFormatter((associatedTrade.preview_result?.orders[0]?.margin_change) - (order.avg_fill_price * 100 * order.total_quantity) + associatedTrade.preview_result?.orders[0]?.commission)}
                {primaryOrder && numberFormatter(primaryOrder.avg_fill_price * 100 * primaryOrder.total_quantity)} */}
                {calculateOrderValue(order, associatedTrade, primaryOrder)}
              </TableCell>
              <TableCell id="qty">
                {renderQuantity(isMultiLeg, order, primaryOrder)}
              </TableCell>
              <TableCell id="status">
                {renderStatus(isMultiLeg, order, primaryOrder)}
              </TableCell>
              <TableCell id="lastUpdatedDate">{formatDateToLocal(primaryOrder?.transaction_date || order.transaction_date)}</TableCell>
            </TableRow>
            {isLimit && openRowId === order.id && (
              <>
                <TableRow className="collapsible-row-background">
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={openRowId === order.id} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Table>
                          <TableBody>
                            {order.legs
                              ?.filter(leg => leg !== primaryOrder)
                              .map((leg, index) => (
                                <TableRow key={`leg-${associatedTrade.id}-${index}`}>
                                  <TableCell style={{ width: "15%" }} className="collapsible-row"></TableCell>
                                  <TableCell style={{ width: "11%" }} id="contract" className="collapsible-row">
                                    {leg.type === "stop" ? "Stop Loss" : "Take Profit"}
                                  </TableCell>
                                  <TableCell style={{ width: "14%" }} id="avgPrice" className="collapsible-row">
                                    {leg.type === "stop" ? numberFormatter(leg.stop_price) : numberFormatter(leg.price)}
                                  </TableCell>
                                  <TableCell style={{ width: "13%" }} id="netCredit" className="collapsible-row">
                                    —
                                  </TableCell>
                                  <TableCell style={{ width: "12%" }} id="orderValue" className="collapsible-row">
                                    —
                                  </TableCell>
                                  <TableCell style={{ width: "8%" }} id="qty" className="collapsible-row">
                                    0
                                    {" "}
                                    /
                                    {" "}
                                    {primaryOrder.total_quantity}
                                  </TableCell>
                                  <TableCell style={{ width: "14%" }} className="collapsible-row">
                                  </TableCell>
                                  <TableCell style={{ width: "11%" }} id="lastUpdated" className="collapsible-row">
                                    {formatDateToLocal(primaryOrder.transaction_date)}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            )}
          </>
        );
      })}

    </>
  );
};

export default OrderHistory;
