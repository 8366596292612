import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const SparklineChart = ({ series, isDecreasing = false }) => {
  const dataPoints = [
    {
      name: "Series 1",
      data: series,
    },
  ];
  const strokeColor = isDecreasing ? "#EF5350" : "#4CAF50";
  const fillColors = isDecreasing
    ? ["#F4433640", "#F4433601"]
    : ["#3EB489", "#3EB48900"];

  const options: ApexOptions = {
    chart: {
      type: "area",
      height: 44,
      sparkline: {
        enabled: true,
      },
      width: 90,
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
      colors: [strokeColor],
    },
    fill: {
      type: "gradient",
      colors: [fillColors[0]],
      gradient: {
        shadeIntensity: 0,
        gradientToColors: [fillColors[1]],
        stops: [0, 100],
      },
    },
  };
  return (
    <Chart
      style={{ lineHeight: "normal !important", paddingLeft: "8px" }}
      options={options}
      series={dataPoints}
      type="area"
      height="44"
      width="90"
    />
  );
};
export default SparklineChart;
