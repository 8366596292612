import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { TradeIdea } from "../../assets/interfaces/interfaces";

export interface CreateTradeIdeaArgs {
  user_id: string;
  trade_idea: object;
  message_id: string;
  combination_id: number;
}
export interface TradeIdeasPayload {
  id: number;
  user_id: string;
  trade_idea: object;
  status: string;
  message_id: string;
  combination_id: number;
}
export interface GetTradeIdeasArg {
  message_id: string;
}
export interface GetTradeIdeasPayload {
  trade_ideas: [];
}
export interface getCompanyNameArgs {
  ticker: string;
  token: string;
}
export interface updateTradeIdeaArgs {
  tradeIdeaId: number;
  previewedOrder: object;
  updatedTradeIdea: object;
}
const initialState = {
  status: "",
  isLoading: null,
  tradeIdeas: [],
  error: null,
  selectedOption: null,
};

export const createTradeIdea = createAsyncThunk(
  "tradeIdea/createTradeIdea",
  async (arg: CreateTradeIdeaArgs, { rejectWithValue }) => {
    try {
      const query = {
        user_id: arg.user_id,
        trade_idea: arg.trade_idea,
        message_id: arg.message_id,
        combination_id: arg.combination_id,
      };
      await axios.post(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-trade-ideas`,
        query,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return null;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const getTradeIdeas = createAsyncThunk(
  "trade/getTradeIdeas",
  async (arg: GetTradeIdeasArg, { rejectWithValue }) => {
    try {
      const query = {
        message_id: arg.message_id,
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-trade-ideas`,
        {
          params: query,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const updateTradeIdea = createAsyncThunk(
  "trade/updateTradeIdea",
  async (arg: updateTradeIdeaArgs, { rejectWithValue }) => {
    try {
      const query = {
        preview_result: arg.previewedOrder,
        trade_idea: arg.updatedTradeIdea,
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-trade-ideas/${arg.tradeIdeaId}`,
        query,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      console.log("updated trade", data[0]); // update this trade in the trades state
      return data[0] || null;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const tradeIdeasSlice = createSlice({
  name: "tradeIdeas",
  initialState,
  reducers: {
    setSelectedOptionByIndex: (state, action: PayloadAction<number>) => {
      const foundOption = state.tradeIdeas.find(trade => trade.id === action.payload);
      state.selectedOption = foundOption;
    },
    updateSelectedTradeAfterExecution: (state, action: PayloadAction<TradeIdea>) => {
      const updatedTrade = action.payload;
      const tradeIndex = state.tradeIdeas.findIndex(trade => trade.id === updatedTrade.id);
      if (tradeIndex !== -1) {
        console.log("updated trade", updatedTrade);
        state.tradeIdeas[tradeIndex] = updatedTrade;
        state.tradeIdeas[tradeIndex].status = "traded";
        state.selectedOption = updatedTrade;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTradeIdea.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTradeIdea.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(getTradeIdeas.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTradeIdeas.fulfilled, (state, action) => {
        state.status = "success";
        state.tradeIdeas = action.payload.sort((a, b) => a.id - b.id);
        state.selectedOption = action.payload[0];
      })
      .addCase(updateTradeIdea.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTradeIdea.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTradeIdea.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setSelectedOptionByIndex, updateSelectedTradeAfterExecution } = tradeIdeasSlice.actions;
export default tradeIdeasSlice.reducer;
